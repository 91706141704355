import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useConn from 'Utils/EndPoints/useConn';
import useUser from 'Utils/EndPoints/useUser';

import './ReserveList.css';
import '../../../Utils/Common/com-style.css';
import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import ReserveListForm from "./components/ReserveListForm";
import ReserveCancelForm from "../ReserveCancel/ReserveCancelForm";
import ReserveCancelComplete from "../ReserveCancelComplete/ReserveCancelComplete"
import {fncgGetUrlPrams, fncgSetUrlPram} from "../../../Utils/Common/ComCom";
import {APP_TOP, PRM_ID} from "../../../Utils/Common/ComConst";

function ReserveList({ appdata }) {
	const [urlpram, dbconn] = useConn(appdata);
	const [user, setUser, loadingUser] = useUser(appdata);

    const [data, setData] = useState({});
    const [disp, setDisp] = useState('0');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [start, setStart] = useState('0');

    /**
     * URLパラメータ取得
     */
    useEffect(() => {
        setStart('1');
    }, [])

    /**
     *
     * @param data
     */
    const onSubmit = data => {
        setLoading(true);

        Http.post('/api/auth/get-reserve-cancel', {
            ...data,

        }).then(response => {
            setData(response.data);
            const strMoveFlg = response.data.move_flg
            if(strMoveFlg == '1'){
                //--　予約キャンセル画面
                setDisp('1');
            }else{
                //-- 予約一覧画面
                setDisp('0');
            }
            window.scrollTo(0, 0);

        }).catch(error => {
            if (error.response) {
                setErrors(error.response.data)
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    /**
     * 予約キャンセル
     * @param data
     */
    const onSubmitCancel = data => {
        setLoading(true);

        Http.post('/api/auth/set-reserve-cancel', {
            ...data,
        }).then(response => {
            setData(response.data);
            const strStatus = response.data.status;
            if (strStatus === '200') {
                //-- 予約一覧画面
                setDisp('2')
            } else if (response.data.error) {
                //-- エラー
                setDisp('2')
                setErrors(response.data.error)
            }
            window.scrollTo(0, 0);

        }).catch(error => {
            if (error.response) {
                setErrors(error.response.data)
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    /**
     * 予約一覧に戻る
     * @param data
     */
    const onSubmitListBack = data => {
        setDisp('0');
    }

	return (
		<>
            {(disp == '0' && user != "" && user != undefined) && (
                <Layout headerText={user.user_nm == undefined? '' : user.user_nm + '様　マイページ　予約確認'} appdata={appdata}>
                    <hr />
                    <ReserveListForm
                        user={user}
                        onSubmitData={onSubmit}
                        dbconn={dbconn}
                    />
                    <hr />
                </Layout >
            )}
            {(disp == '1' && user != "" && user != undefined) && (
                <Layout headerText="予約キャンセル確認" appdata={appdata}>
                    <hr />
                    <ReserveCancelForm
                        user={user}
                        data={data}
                        dbconn={dbconn}
                        onSubmitCancel={onSubmitCancel}
                        onSubmitListBack={onSubmitListBack}
                    />
                    <hr />
                </Layout >
            )}
            {disp === '2' && (
                <ReserveCancelComplete
                    data={data}
                    onSubmitListBack={onSubmitListBack}
                />
            )}
		</>
	);
}

export default ReserveList;
