// import React from 'react';
import React, { useState, useEffect } from 'react';

import './Default.css';
import Footer from 'Component/Footer/Footer';
import CardHeader from 'Component/CardHeader/CardHeader';
import Header from 'Component/Header/Header';
import { Container } from 'react-bootstrap';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

function Layout(props) {

	const { history, mainContentClass, appdata, noheader, nonotification } = props;
	const mainContentClassName = mainContentClass ? `${mainContentClass} main-content` : 'main-content'
	return (
		<div className="wrapper">
			<Container>	
				{!noheader && <Header history={history} appdata={appdata}/>}
				{props.headerText &&
	 				<CardHeader text={props.headerText} />
				}
				<div className={mainContentClassName}>
					{props.children}
				</div>
				{!nonotification && <NotificationContainer/>}
			</Container>
			<Footer appdata={appdata} />
		</div>
	);
}

export default Layout;