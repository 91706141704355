import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

export function PasswordResetRow(props) {
    return (
        props.isMobile ? (
            <Row className="div-login-forget-password sp">
                <div className="login-forgot-password">
                    パスワードをお忘れの方は
                    <a className="a-forgot" style={{color: '#1231C4'}} onClick={props.onClick}>こちら</a>
                </div>
            </Row>
        ) : (
            <Row className="justify-content-md-center disp-left-space-area disp-right-space-area pc">
                <div className="login-forgot-password">
                    パスワードをお忘れの方は
                    <a className="a-forgot" style={{color: '#1231C4'}} onClick={props.onClick}>こちら</a>
                </div>
            </Row>
        )
    );
}

export function NewRegisterRow(props) {
    let btnClassName = props.btnClassName ?? '';

    return (
        props.isMobile ? (
            <Row className="row-new-login sp">
                <div className="div-new-login">
                    <label className={"label-new-login"}>
                        初めてご利用の方
                    </label>
                </div>
                <div className="div-login-button">
                    <Button
                        className={props.regStop ? (btnClassName + " register-stop") : btnClassName}
                        onClick={props.onClick}
                        disabled={props.regStop}
                    >
                        会 員 登 録 へ
                    </Button>
                </div>
            </Row>
        ) : (
            <Row className="disp-left-space-area disp-right-space-area pc-flex">
                <Col md={{ span: 3, offset: 3 }} className="login-input-label">				
                    初めてご利用の方
                </Col>	
                <Col md={{ span: 3}}>	
                <Button 
                    className={props.regStop ? (btnClassName + " register register-stop") : (btnClassName + " register")}
                    onClick={props.onClick}
                    disabled={props.regStop}
                >
                    会 員 登 録 へ
                </Button>
                </Col>	
            </Row>
        )
    );
}