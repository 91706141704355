import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useConn from 'Utils/EndPoints/useConn';

import './ForgotPassword.css';
import Layout from 'layouts/Default/Default';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import usePost from 'Utils/EndPoints/usePost';
import { Button, Col, Row , Modal, Container} from 'react-bootstrap';
import {APP_TOP, PRM_ID} from "../../../Utils/Common/ComConst";

function ForgotPassword ({ appdata }){
	let history = useHistory();
	const [ urlpram, dbconn, loadingConn ] = useConn(appdata);		

    const [result, submitPost, errors, setErrorClear, isLoading] = usePost();
    const [show, setShow] = useState(false);
    const [start, setStart] = useState('0');

    /**
     * URLパラメータ取得
     */
    useEffect(() => {
        if( loadingConn ) return;
        setStart('1');
    }, [loadingConn])

    /**
     * 送信結果処理(正常時)
     */
    useEffect(() => {
        if (Object.keys(result).length > 0) {
            setShow(true)
        }
    }, [result]);

    /**
     * 送信処理
     * @param data
     */
    const onSubmit = data => {
        submitPost('/api/password/forgot-password', data);
    }

    /**
     * モーダル画面グローズ
     */
    function handleClose() {
		setShow(false);
		history.push(APP_TOP + urlpram);
    }

    return (
        <>
            <Layout headerText="パスワード再発行">
                <div className="forgot-password-details disp-left-space-area disp-right-space-area">
                    <label className="forgot-password-details-label1">
                        パスワードをお忘れの方は、こちらからパスワードの再設定をしてください。
                    </label>
                    <br/>登録時のメールアドレスを入力して送信ボタンを押してください。
                    <br/>メールにてパスワード変更用のURLを発行します。
                    <br/>10分以内にアクセスし、新しいパスワードを設定してください。
                </div>
                <p className="text-header disp-left-space-area disp-right-space-area">
                    メールアドレス送信
                </p>
                <ForgotPasswordForm
                    onSubmitData={onSubmit}
                    errors={errors}
                    isLoading={isLoading}
                    dbconn={dbconn}
                />
            </Layout >
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>メッセージ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    パスワード変更用URLを送信いたしました。
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ForgotPassword;

