import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import useConn, {IsDbconnId} from 'Utils/EndPoints/useConn';
import useUser, {IsTempUser} from 'Utils/EndPoints/useUser';
import useShop, {IsEmailAuth} from 'Utils/EndPoints/useShop';
import useFetch from 'Utils/EndPoints/useFetch';

import Http from 'Utils/Axios';
import Auth from 'Utils/Auth';

import './LineLogin.css';
import Layout from 'layouts/Default/Default';
import LineLoginForm from './components/LineLoginForm';
import {APP_TOP, MODE_NEW, PRM_ID} from "../../../Utils/Common/ComConst";
import {NewRegisterRow} from '../../Login/components/Parts'
import {NotificationContainer} from 'react-notifications';

function LineLogin({ info, submitReserve, submitTop, appdata}) {
	let history = useHistory();
	let location = useLocation();
	let { token } = useParams();

	const [urlpram, dbconn, loadingConn] = useConn(appdata);
	const [user, setUser, loadingUser] = useUser(appdata);
	const [shop, setShop, loadingShop] = useShop(appdata, dbconn);

	const [checkedToken, setCheckedToken] = useState(false);
	const [loading, setLoading] = useState(true);
	const [hidden, setHidden] = useState(true);
	const [errors, setErrors] = useState({});

	const [fetchedData] = useFetch();
	const [aryData, setAryData] = useState({
		id: '',
		line_id: ''
	});

	// 初期処理
	useEffect(() => {
		if (loadingConn || loadingUser) return;
		if (user) {
			//ログインの整合性が取れないのでセッションは強制ログアウト
			Http.post('api/auth/logout').then(response => {
			}).catch(error => {
				
			}).finally(() => {
				Auth.SetAccessToken();
				appdata.init();
				history.go(0);
			});
			return;
		}

		//--- トークンチェック
		let paramData = {
			token : token,
			id : dbconn.id,
		}
		Http.post('api/token-id', {
			...paramData
		}).then(response => {
			let strStatus = response.data.status;
			let line_id = response.data.results.line_id;
			if (strStatus == 200) {
				aryData.line_id = line_id;
				aryData.id = dbconn.id;
				setAryData(aryData);
			}
			setCheckedToken(true);
		}).catch(error => {
			history.push(APP_TOP + urlpram);
		}).finally(() => {
		});
	}, [loadingConn || loadingUser])

	useEffect(() => {
		if (loadingConn || loadingShop || loadingUser || !checkedToken) return;
		if (user) {
			if (IsTempUser(user)) {
				// 仮登録会員
				fncgAddSignUpToken(true);
			} else {
				history.push(APP_TOP + urlpram);
			}
		} else {
			if (loading) setLoading(false);
			if (hidden) setHidden(false);
		}
	}, [loadingConn, loadingShop, loadingUser, checkedToken])

	useEffect(() => {
		if(fetchedData.status){
			history.push('/sign-up/' + fetchedData.results.token)
		}
	}, [fetchedData]);

	/**
	 * ログイン
	 * @param data
	 */
	const onSubmit = data => {
		setLoading(true);
		data.link_token = aryData.line_id;

		Http.post('/api/line/login', {
			...data
		}).then(response => {
			if(response.status == '200'){
				// localstorageに保存
				Auth.SetAccessToken(response.data.token)
				
				let from = location.state;
				if (from) {
					history.replace(from);
					return;
				}

				if (response.data.isTempUser) {
					// 仮登録会員
					fncgAddSignUpToken(true);
				} else {
					history.push(APP_TOP + urlpram);
				}
			} else {
				setErrors(response.data);
				setLoading(false);
			}
		}).catch(error => {

			if (error.response) {
				setErrors(error.response.data)
			}
			Auth.SetAccessToken();
			setLoading(false);
		}).finally(() => {
		})
	}

	/**
	 * 会員登録
	 */
	function fncgAddSignUpToken(isTmpUser) {
		if(aryData.line_id == undefined || aryData.line_id == '') {
			history.push(APP_TOP + urlpram);
			return;
		}
		let paramData ={
			id : aryData.id,
			value : aryData.line_id,
		}
		Http.post('api/token-signup',{...paramData}).then(response => {
			let strToken = response.data.results.token;
			history.push('/line-registration/detail/'+ strToken + urlpram);
		}).catch(error => {
			console.info(error)
		});
	}

	/**
	 * 新規登録
	 */
	 function fncNewRegister() {
		fncgAddSignUpToken(false);
	}

	return (
		<Layout noheader nonotification appdata={appdata}>
			<div className="login-card-header-container-line">
				<div className="pc login-card-header disp-left-space-area">
					{"ログイン"}
				</div>
				<div className="sp sp-size-title">
					{"ログイン"}
				</div>
			</div>
			<NotificationContainer/>
			{(hidden) ? (
				<p className="text-header-login disp-left-space-area disp-right-space-area">
					お待ちください。。。
				</p> 	
			) : (
				<div className="main-content">
					<p className="text-header-login disp-left-space-area disp-right-space-area">
						登録されたメールアドレスとパスワードを入力してログインボタンを押してください。
					</p>
					<hr />
					<LineLoginForm
						onSubmitData={onSubmit}
						errors={errors}
						dbconn={dbconn}
						loading={loading}
					/>
					<hr />

					<NewRegisterRow onClick={fncNewRegister} regStop={shop.registration_stop_flg == '1'} btnClassName="login-button" />

					<NewRegisterRow onClick={fncNewRegister} regStop={shop.registration_stop_flg == '1'} btnClassName="button-new-login" isMobile />
				</div>
			)}
		</Layout>
	);
}

export default LineLogin;
