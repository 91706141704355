import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import useConn, {IsDbconnId} from 'Utils/EndPoints/useConn';
import useUser, {IsTempUser} from 'Utils/EndPoints/useUser';
import useShop, {IsEmailAuth} from 'Utils/EndPoints/useShop';
import useFetch from 'Utils/EndPoints/useFetch';

import Http from 'Utils/Axios';
import Auth, {IsOlderSettings} from 'Utils/Auth';

import './Login.css';
import Layout from 'layouts/Default/Default';
import LoginForm from './components/LoginForm';
import {APP_TOP, PRM_ID, MODE_NEW, MODE_TMP} from "../../Utils/Common/ComConst";
import {PasswordResetRow, NewRegisterRow} from './components/Parts'

function Login({ appdata, info, submitReserve }) {
	let history = useHistory();
	let location = useLocation();

	const [urlpram, dbconn, loadingConn] = useConn(appdata);
	const [user, setUser, loadingUser] = useUser(appdata);
	const [shop, setShop, loadingShop] = useShop(appdata, dbconn);
	const [fetchedData] = useFetch();

	const [loader, setLoader] = useState(true);
	const [hidden, setHidden] = useState(true);
	const [errors, setErrors] = useState({});
	const [start, setStart] = useState('0');

	const IsReset = location.pathname.indexOf('/reset') != -1;

	useEffect(() => {
		if (loadingConn) return;
		if (IsReset) {
			Auth.SetAccessToken();
			appdata.init();
			history.replace('/login' + urlpram);
			history.go(0);
			return;
		}
		setStart('1');
	}, [loadingConn])

	useEffect(() => {
		if (loadingShop) return;
		if (!shop) history.push('/not-found' + urlpram);
		if(info != undefined){
			info.id = dbconn.id;
		}
	}, [loadingShop])

	useEffect(() => {
		if (loadingUser || loadingShop) return;
		if (user) {
			// ログイン済み
			if (IsTempUser(user)) {
				fncgAddSignUpToken(true);
			} else {
				history.push(APP_TOP + urlpram);
			}
		} else {
			if (loader) setLoader(false);
			if (hidden) setHidden(false);
		}
	}, [loadingUser, loadingShop])

	useEffect(() => {
		if(fetchedData.status){
			history.push('/sign-up/' + fetchedData.results.token)
		}
	}, [fetchedData]);

	/**
	 * ログイン
	 * @param data
	 */
	const onSubmit = data => {
		setLoader(true);

		Auth.SetAccessToken();
		Http.post('/api/login', {
			...data,
		}).then(response => {
			if(response.status == '200'){
				// localstorageに保存
				Auth.SetAccessToken(response.data.token)
				
				if (location.state) {
					let { from } = location.state;
					if (from) {
						history.replace(from);
						return;
					}
				}

				if (response.data.isTempUser) {
					// 仮登録会員
					fncgAddSignUpToken(true);
				} else {
					// ログイン後の遷移先設定
					if(info == undefined) {
						history.push(APP_TOP + urlpram);
					}else if(Object.keys(info).length > 0){
						submitReserve(info);
					}else{
						history.push(APP_TOP + urlpram);
					}
				}
			}else{
				setErrors(response.data)
				setLoader(false);
			}
		}).catch(error => {
			if (error.response) {
				setErrors(error.response.data)
			}
			setLoader(false);
		}).finally(() => {
		})
	}

	/**
	 * 会員登録
	 */
	function fncgAddSignUpToken(isTmpUser) {
		//--- メール認証判定
		if(IsEmailAuth(shop)){
			//-- 有効
			//- メールアドレス登録画面へ（仮会員であってもメール認証させます）
			Http.post('api/sign-up/token-generation',{...dbconn}).then(response => {
				let strToken = response.data.results.token;
				history.push('/sign-up/' + strToken + urlpram);
			});
		}else{
			//-- 無効
			//- 会員登録画面へ
			history.push('/registration/detail' + urlpram);
		}
	}

	/**
	 * 新規登録
	 */
	function fncNewRegister() {
		fncgAddSignUpToken(false);
	}

	/**
	 * パスワード忘れ
	 */
	function fncForgotPasswork(){
		history.push('/password/forgot-password' + urlpram);
	}

	return (
		<Layout headerText="ログイン" appdata={appdata}>
			{(hidden) ? (
				<p className="text-header-login disp-left-space-area disp-right-space-area">
					お待ちください。。。
				</p> 	
			) : (
				<>
				<p className="text-header-login disp-left-space-area disp-right-space-area">
					登録されたメールアドレスとパスワードを入力してログインボタンを押してください。
				</p> 	
				<hr />
				<LoginForm
					onSubmitData={onSubmit}
					errors={errors}
					dbconn={dbconn}
					loading={loader}
				/>
				<hr />
				{(shop) && (
					<>
					{/** PC */}
					<PasswordResetRow onClick={fncForgotPasswork} />
					<NewRegisterRow onClick={fncNewRegister} regStop={shop.registration_stop_flg == '1'} btnClassName="register-button" />
					{/** Mobile */}
					<PasswordResetRow onClick={fncForgotPasswork} isMobile />
					<NewRegisterRow onClick={fncNewRegister} regStop={shop.registration_stop_flg == '1'} btnClassName="button-new-register" isMobile />
					</>
				)}
				</>
			)}
		</Layout >
	);
}

export default Login;
