import React from 'react';
import './Required.css';

function Required() {
	return (
		<div className="required-component">			
			必須
		</div>
	)
}

  
  export default Required;