import React, {useEffect, useState} from 'react';

import { useHistory, useParams, useLocation } from 'react-router-dom';
import useConn, {IsDbconnId} from 'Utils/EndPoints/useConn';
import useUser, {IsTempUser} from 'Utils/EndPoints/useUser';
import useShop, {IsEmailAuth} from 'Utils/EndPoints/useShop';

import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import RegistrationDetailForm from '../../Registration/RegistrationDetail/RegistrationDetailForm/RegistrationDetailForm';
import RegistrationFormVerify from '../../Registration/RegistrationDetail/RegistrationFormVerify/RegistrationFormVerify';
import {NotificationContainer} from 'react-notifications';

export default function RegistrationDetail({ appdata }) {
	let location = useLocation();
	let history = useHistory();
	let { token } = useParams();
	const [urlpram, dbconn, loadingConn] = useConn(appdata);
	const [user, setUser, loadingUser] = useUser(appdata);
	const [shop, setShop, loadingShop] = useShop(appdata, dbconn);

	const [checkedToken, setCheckedToken] = useState(false);
	const [data, setData] = useState({});
	const [verify, setVerify] = useState(false);
	const [rawData, setRawData] = useState({});
	const [loader, setLoader] = useState(true);

	/**
	 * URLパラメータ取得
	 */
	 useEffect(() => {
		if (loadingConn || loadingUser || loadingShop || !checkedToken) return;
		if (user) {

		}
		if (!shop) {
			history.push('/not-found' + urlpram);
		}
		setLoader(false);
	}, [loadingConn, loadingUser, loadingShop, checkedToken])

	/**
	 * 初期処理
	 */
	 useEffect(() => {
		if(loadingConn) return;

		let aryData = {
			token : token,
			id : dbconn.id,
		}
		Http.post('api/token-id', {
			...aryData
		}).then(response => {
			if (response.data.status == 200) {
				setRawData({
					results: {...response.data.results},
					status: response.data.status,
				});
			} else {
				setRawError();
			}
		}).catch(error => {
			setRawError();
		}).finally(() => {
			setCheckedToken(true);
		});
	},[loadingConn]);

	function setRawError() {
		setRawData({error : 'token valid error.'});
	}

	function handleSubmitForVerify(data) {
		setData(data);
		setVerify(true);
	}

	function handleSubmitForRegistration(data) {
		setData(data)
		setVerify(false);
	}

	const Loader = () => {
		return (<div className="main-content">お待ちください。。。</div>);
	};

	return (
		<Layout noheader nonotification appdata={appdata}>
			<div className="login-card-header-container-line">
				<div className="pc login-card-header disp-left-space-area">
					{"会員登録"}
				</div>
				<div className="sp sp-size-title">
					{"会員登録"}
				</div>
			</div>
			<NotificationContainer/>
			{loader ? <Loader /> : shop != "" && shop != undefined && (
				<div className="main-content">
					{!verify ? (
						<RegistrationDetailForm
							token={token}
							onSubmitDataForVerify={handleSubmitForVerify}
							data={data}
							rawData={rawData}
							shop={shop}
							user={user}
							dbconn={dbconn}
							history={history}
							line
						/>
					) : (
						<RegistrationFormVerify
							token={token}
							history={history}
							returnRegistrationForm={handleSubmitForRegistration}
							data={data}
							shop={shop}
							dbconn={dbconn}
							urlpram={urlpram}
							isAuth={user ? true : false}
							line
						/>
					)}
				</div >
			)}
		</Layout>
	);

}
