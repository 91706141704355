import React, { useEffect, useState	 } from 'react';

import './ReserveCancel.css';
import '../../../Utils/Common/com-style.css';
import {Link, withRouter} from 'react-router-dom';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData';
import {Button, Col, Row, Form} from 'react-bootstrap';

const defaultState = {
	reserve_id : "",
	user_id : "",
	id : "",
};

function ReserveCancelForm({user, data, dbconn, onSubmitCancel, onSubmitListBack}) {
	const [fieldData] = useHandleFormData(defaultState);
	const [loading, setLoading] = useState('0');

	function handleSubmit(e){
		setLoading('1');
		fieldData.reserve_id = data.reserve_id;
		fieldData.user_id = user.user_id;
		fieldData.id = dbconn.id;
		e.preventDefault();
		onSubmitCancel(fieldData);
		setLoading('0');
	}

	function fncOnClickBack()
	{
		fieldData.id = dbconn.id;
		onSubmitListBack(fieldData);
	}

	return (
		<>		
			<Form className="reserve-form-verify" onSubmit={handleSubmit}>
				<div className="text-header first-header disp-left-space-area">
					▼予約
				</div>
				<Row className="reserve-cancel-form-row">
					<Row className="reserve-cancel-value">
						予約者
					</Row>
					<Row className="reserve-cancel-value">
						{data.user_nm}様
					</Row>	
				</Row>
				<Row className="reserve-cancel-form-row">
					<Row className="reserve-cancel-value">
						予約日
					</Row>
					<Row className="reserve-cancel-value">
						{data.reserve_date}
					</Row>
				</Row>
				<Row className="reserve-cancel-form-row">
					<Row className="reserve-cancel-value">
						時間
					</Row>
					<Row className="reserve-cancel-value">
						{data.course_start_time} ～
					</Row>	
				</Row>
				<Row className="reserve-cancel-form-row">
					<Row className="reserve-cancel-value">
						スタッフ
					</Row>
					<Row className="reserve-cancel-value">
						{data.staff_nm}
					</Row>	
				</Row>
				<Row className="reserve-cancel-form-row">
					<Row className="reserve-cancel-value">
						コース
					</Row>
					<Row className="reserve-cancel-value">
						{data.course_nm + '(' + data.course_time + '分)'}
					 </Row>
				</Row>

				<div className="text-header first-header disp-left-space-area">
					▼ご要望等
				</div>

				<Row className="reserve-cancel-form-row">
					<textarea
						className="reserve-cancel-input-field"
						cols="100"
						rows="4"
						value={data.reserve_note}
						name="reserve_note"
						readOnly="readOnly"
					/>
				</Row>

				<Row className="justify-content-md-center reserve-cancel-form-row">
					<Col className="pc" md={{span: 2, offset: 8 }}>
						<Button
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
					<Col className="pc" md={{span: 2}}>
						<Button
							className="reserve-verify-button"
							type="submit"
							variant="secondary"
							disabled={loading == '1'? 'disabled' : ''}
						>
							キャンセル
						</Button>
					</Col>
					<Col className="sp" xs={{span: 4}}>
						<Button
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
					<Col className="sp" xs={{span: 4, offset: 4}}>
						<Button
							className="reserve-verify-button"
							type="submit"
							variant="secondary"
							disabled={loading == '1'? 'disabled' : ''}
						>
							ｷｬﾝｾﾙ
						</Button>
					</Col>
				</Row>
			</Form>
		</>
	)
}

export default withRouter(ReserveCancelForm);