import React, { useState, useEffect } from 'react';
import {fncgGetUrlPrams, fncgSetUrlPram} from "Utils/Common/ComCom";
import {PRM_ID} from "Utils/Common/ComConst";
import Http, {CancelToken} from 'Utils/Axios';

function useConn(defData) {
    /** 全てのDB読み込みが完了した時点でfalseが設定されます。 */
    const [loading, setLoading] = useState(true);
    /** 共通のurlパラメータです */
    const [urlpram, setUrlPram] = useState('');
    /** dbconn.id = url.id、dbconn.dbname = db.name */
    const [dbconn, setDbConn] = useState({id: null, dbname: null});

    const source = CancelToken.source();

    function MargeDbConn(src) {
        if (!src) return;
        Object.keys(src).forEach(key => {
            dbconn[key] = src[key];
        });
        setDbConn({...dbconn});
    }

    /**
     * URL確認
     */
    useEffect(() => {
        let mount = true;
        let conn = fncgGetUrlPrams(document.location.href, PRM_ID);
        let pram = fncgSetUrlPram(conn);
        setUrlPram(pram);

        let cache = null;
        if (defData && 'dbconn' in defData) cache = defData.dbconn;
        if (cache != null && conn.id == cache.id) {
            // キャッシュから取得
            MargeDbConn(cache);
            setLoading(false);
        } else {
            //--- DBチェック
            if (mount) loadDB(true, conn);
        }

        return () => {
            mount = false;
        }
    }, [])

    function loadDB(force, conn) {
        if (!force && loading) return;

        if (!conn) conn = dbconn;
        if (conn && !IsDbconnId(conn)) {
            conn.dbname = null;
            MargeDbConn(conn);
            setLoading(false);
            return;
        } else {
            MargeDbConn(conn);
            setLoading(true);
        }

        let aryData ={
            id : conn.id,
        };
        setDbConn({...dbconn, dbname: null});
        Http.post('api/db_name',{...aryData},{cancelToken: source.token}).then(response => {
            if(response.data.dbname == 'error'){
            }else{
                setDbConn({...dbconn, dbname: response.data.dbname});
            }
        }).catch(error => {

        }).finally(() => {
            setLoading(false);
        });
    }

    function cancel() {
        source.cancel('Operation canceled by the user.');
    }
    
    return [urlpram, dbconn, loading, cancel];
}

/**
 * DB接続チェック
 */
 export function IsDbconnId(dbconn){
    return dbconn.id !== '' && dbconn.id !== undefined
}

/**
 * URLIDを強制取得します。
 * 通常はuseConn.dbconnから取得してください。
 */
export function GetDbconn(){
    return fncgGetUrlPrams(document.location.href, PRM_ID);
}

export default useConn;