import React from 'react';
import { IsTempUser } from '../../../Utils/EndPoints/useUser';

import { IoMdPerson } from "react-icons/io";
import { Dropdown } from 'react-bootstrap';
import { detectInAppBrowser } from '../../../Utils/Common/ComCom';

function MyPageLinks({ topDisp, logout, reserveList, reserveHistory, userUpdate, user }) {
	const isLine = detectInAppBrowser().indexOf('line') > 0;
	const nologout = !IsTempUser(user) && (!user.email || user.email == '') || (!user.login_pass || user.login_pass == '');
	const tempUser = IsTempUser(user);

	function handleTopDisp() {
		topDisp();
	}

	function handleReserveList() {
		reserveList();
	}

	function handleReserveHistory() {
		reserveHistory();
	}

	function handleUserUpdate() {
		userUpdate();
	}

	function handleLogout() {
		logout();
	}

	return (
		<Dropdown>
			<Dropdown.Toggle className={"header-btn-mypage"} variant="success" id="dropdown-basic">
				<IoMdPerson className={"header-icon-mypage"}/>マイページ
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item onClick={handleTopDisp} >TOP画面</Dropdown.Item>
				{!tempUser && <Dropdown.Item onClick={handleReserveList} >予約一覧</Dropdown.Item>}
				{!tempUser && <Dropdown.Item onClick={handleReserveHistory} >予約履歴</Dropdown.Item>}
				{!tempUser && <Dropdown.Item onClick={handleUserUpdate}>会員情報の変更</Dropdown.Item>}
				{!isLine && !nologout && <Dropdown.Item onClick={handleLogout} >ログアウト</Dropdown.Item>}
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default MyPageLinks;