import React, { useState, useEffect } from 'react';
import {IsDbconnId} from 'Utils/EndPoints/useConn';

import '../../../Utils/Common/com-style.css';
import '../Top.css';
import Http from '../../../Utils/Axios';
import useHandleFormData from '../../../Utils/FormHandlers/useHandleFormData'
import { Button, Col, Row, Form, Modal, FormControl} from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import '../../../Utils/Common/react-datepicker.css';
import { IoIosCalendar } from "react-icons/io";
registerLocale('ja', ja);

const dtNow = new Date();
const list_color = '#c5e4e7';
const defaultState = {
	user_id : "",
	staff_id : "",
	course_id : "",
	reserve_date : dtNow.getFullYear() + '-' + (dtNow.getMonth() + 1) + '-' + dtNow.getDate(),
	reserve_time : "",
	week_kbn : "0",
	index_date : "0",
	max_cnt : "0",
	move_flg : "",
	date_flg : "0",
	id : "",
	disp_type : "0",
	before_date : "",
	next_date : "",
	top_date : "",
	rsv_week_cnt : "",
}

function TopForm({ onSubmitData, errors, dbconn }) {
	const [staff, setStaff] = useState('');
	const [course, setCourse] = useState('');
	const [reserve, setReserve] = useState('');
	const [data] = useHandleFormData(defaultState);
	const [selstaff, setSelStaff] = useState('');
	const [selcourse, setSelCourse] = useState('');
	const [datebefflg, setDateBefFlg] = useState('');
	const [dateflg, setDateFlg] = useState('');
	const credentialError = '';
	const [modalShow, setModalShow] = useState(false);
	const [event, setEvent] = useState(false);
	const [eventStaff, setEventStaff] = useState('');
	const [listflg, setListFlg] = useState('0');
	const [topDate, setTopDate] = useState(dtNow);
	const [cldMsg, setCldMsg] = useState('');
	const [rsvWkCnt, setRsvWkCnt] = useState('');
	const [shopTel, setShopTel] = useState('');

	const [loadingCourse, setLoadingCourse] = useState(false);
	const [loadingStaff, setLoadingStaff] = useState(false);
	const [loadingReserve, setLoadingReserve] = useState(false);
	
	let CheckWindowSize = () => {
		let windowSize = window.outerWidth;
		if(windowSize <= 500){
			data.disp_type = "1";
		}else{
			data.disp_type = "0";
		}
	};

	/**
	 * ユーザー情報取得
	 */
	useEffect(() => {
		data.course_id = '';
		data.staff_id = '';

		CheckWindowSize();
	}, [])

	useEffect(() => {
		setModalShow(loadingCourse || loadingStaff || loadingReserve);
	}, [loadingCourse, loadingStaff, loadingReserve])

	/**
	 * コース情報取得
	 */
	useEffect(() => {
		if( !IsDbconnId(dbconn)) return;

		if(loadingCourse) return;
		setLoadingCourse(true);

		setSelCourse('');
		setCourse('');

		data.id = dbconn.id;
		Http.post('api/get-course', {...data},
		).then(response => {
			if (Object.keys(response.data).length > 0) {
				data.course_id = response.data[0].course_id;
				setSelCourse(response.data[0].course_id);
				setCourse(response.data);
			}else{
				setSelCourse('');
				setCourse('');
			}
		}).catch(error => {
		}).finally(() => {
			setLoadingCourse(false);
		});
	}, [])

	/**
	 * スタッフ情報取得
	 */
	useEffect(() => {
		if( !IsDbconnId(dbconn) ||
		    data.course_id == undefined || data.course_id == '') return;

		if(loadingCourse || loadingStaff) return;
		setLoadingStaff(true);

		setSelStaff('');
		setStaff('');

		data.id = dbconn.id;
		Http.post('api/get-staff',{...data}).then(response => {
			if (Object.keys(response.data).length > 0) {
				data.staff_id = response.data[0].staff_id;
				setSelStaff(response.data[0].staff_id);
				setStaff(response.data);
			}else{
				setSelStaff('');
				setStaff('');
			}
		}).catch(error => {
		}).finally(() => {
			setLoadingStaff(false);
		});
	}, [selcourse, loadingCourse])

	/**
	 * 予約情報取得
	 */
	useEffect(() => {
		if( !IsDbconnId(dbconn) ||
			data.staff_id == undefined || data.staff_id == '' ||
			data.course_id == undefined || data.course_id == '') return;

		if(loadingCourse || loadingStaff || loadingReserve) return;
		setLoadingReserve(true);
				
		//--- 画面サイズ判定
		CheckWindowSize();
		
		setReserve('');

		// コースIDセット
		data.course_id = selcourse;

		//--- 予約情報取得
		Http.post('api/get-reserve', {...data}).then(response => {
			if (Object.keys(response.data).length > 0) {
				if(response.data.error == '' || response.data.error == undefined){
					setReserve(response.data);
					setDateFlg(response.data[0].ListInfo.DateFlg);
					setDateBefFlg(response.data[0].ListInfo.DateBefFlg);
					setRsvWkCnt(response.data[0].ListInfo.ReserveWeekCnt);
					setShopTel(response.data[0].ListInfo.ShopTel);
				}
			}
		}).catch(error => {
		}).finally(() => {
			setLoadingReserve(false);
		});
	}, [selstaff, event, loadingCourse, loadingStaff])

	/**
	 * カレンダー日付セット
	 */
	useEffect((topDate) => {
		if(data.reserve_date == undefined || data.reserve_date == '') return;
	}, [topDate])

	/**
	 * スタッフ一覧作成
	 * @returns {*}
	 */
	function fncGetStaffList(){
		var lstStaff = [];
		for(var intIdx in staff){
			lstStaff.push(
				<tr className={"tr-staff"} key={intIdx}>
					<td className={"check-staff"} id={"td-staff-" + intIdx} style={{backgroundColor: intIdx == 0? list_color : ''}}>
						<label className={"list-label"}>
							<input
								type={"radio"}
								name="rdo-staff"
								id={'rdo-staff-' + intIdx}
								onClick={fncOnClickStaff}
								data-id={intIdx}
								defaultChecked={intIdx == 0? 'checked': ''}
							/>
							　{staff[intIdx].staff_disp_nm != null? staff[intIdx].staff_disp_nm : staff[intIdx].staff_nm}
						</label>
						<input type="hidden" id={'hdn-staff-' + intIdx} value={staff[intIdx].staff_id}></input>
					</td>
				</tr>
			);
		}

		if(lstStaff.length > 0){
			return(
				<tbody>
					{lstStaff}
				</tbody>
			);
		}else{
			return(
				<tbody></tbody>
			);
		}
	}

	/**
	 * コース一覧作成
	 * @returns {*}
	 */
	function fncGetCourseList(){
		var lstCourse = [];
		for(var intIdx in course){
			lstCourse.push(
				<tr className={"tr-course"} key={intIdx}>
					<td className={"check-course"} id={"td-course-" + intIdx} style={{backgroundColor: intIdx == 0? list_color : ''}}>
						<label className={"list-label"}>
							<input
								type={"radio"}
								name="rdo-course"
								id={'rdo-course-' + intIdx}
								onClick={fncOnClickCourse}
								data-id={intIdx}
								defaultChecked={intIdx == 0? true:false}
							/>
							　{course[intIdx].course_nm}
						</label>
						<input type="hidden" id={'hdn-course-' + intIdx} value={course[intIdx].course_id}></input>
					</td>
				</tr>
			);
		}

		if(lstCourse.length > 0){
			return(
				<tbody>
				{lstCourse}
				</tbody>
			);
		}else{
			return(
				<tbody></tbody>
			);
		}
	}

	//--- TELが存在するかどうか
	let isTel = false;

	/**
	 * 予約情報作成
	 * @returns {*}
	 */
	function fncSetReserveList(){
		var lstReserve = [];
		var lstReserveSp = [];

		if(reserve == '' || reserve == undefined) return;
		if(reserve[0].Header == undefined) return;
		if(reserve[0].ListInfo == undefined) return;

		data.reserve_date = reserve[0].Header.CalendarDate;
		data.index_date = reserve[0].ListInfo.SelIdx;
		data.max_cnt = reserve[0].ListInfo.MaxCnt;
		data.before_date = reserve[0].ListInfo.BeforeDate;
		data.next_date = reserve[0].ListInfo.NextDate;
		//data.date_flg = reserve[0].ListInfo.DateFlg;

		//--- ヘッダー
		var lstHeader = [];
		var lstHeaderSp = [];
		lstHeader.push(<th className={"th-date-0"} key={'0'}></th>);
		lstHeaderSp.push(<th className={"th-date-0-sp sp-size"} key={'0'}>{reserve[0].Header.TitleDate.split('/')[0]}月</th>);
		for(var intIdx in reserve){
			lstHeader.push(
				<th
					className={"th-date-" + (Number(intIdx) + 1)}
					key={Number(intIdx) + 1}
					style={{backgroundColor: reserve[intIdx].Header.WeekKbn == '5'? "#ccdae8" :
											 reserve[intIdx].Header.WeekKbn == '6'? "#f1cdc7" :
											 reserve[intIdx].Header.WeekKbn == '7'? "#f1cdc7" : "#E5EDE0"}}
				>
					{reserve[intIdx].Header.TitleDate}
				</th>
			);
			lstHeaderSp.push(
				<th
					className={"sp-size th-date-" + (Number(intIdx) + 1) + "-sp"}
					key={Number(intIdx) + 1}
					style={{backgroundColor: reserve[intIdx].Header.WeekKbn == '5'? "#ccdae8" :
							reserve[intIdx].Header.WeekKbn == '6'? "#f1cdc7" :
								reserve[intIdx].Header.WeekKbn == '7'? "#f1cdc7" : "#E5EDE0"}}
				>
					<Button
						className="header-calendar"
						style={{backgroundColor: reserve[intIdx].Header.WeekKbn == '5'? "#ccdae8" :
								reserve[intIdx].Header.WeekKbn == '6'? "#f1cdc7" :
									reserve[intIdx].Header.WeekKbn == '7'? "#f1cdc7" : "#E5EDE0"}}
						disabled={"disabled"}
					>
						{reserve[intIdx].Header.TitleDate.split('/')[1].replace('(', '').replace(')', '')}
					</Button>
				</th>
			);
		}

		//--- データ
		var lstDetail = [];
		var lstDetailSp = [];
		for(var intIdx in reserve[0].Detail){
			var lstData = [];
			var lstDataSp = [];
			lstData.push(
				reserve[0].Detail[intIdx].EndFlg != '1' ?
					<th className={"td_time"} key={'time-' + intIdx}>{reserve[0].Detail[intIdx].Time}</th>
					:
					<th className={"td_time reserve-end-time"} key={'time-' + intIdx}>{reserve[0].Detail[intIdx].Time}</th>
			);
			lstDataSp.push(
				reserve[0].Detail[intIdx].EndFlg != '1' ?
					<th className={"td_time-sp sp-size"} key={'time-sp-' + intIdx}>{reserve[0].Detail[intIdx].Time}</th>
					:
					<th className={"td_time-sp sp-size reserve-end-time"} key={'time-sp-' + intIdx}>{reserve[0].Detail[intIdx].Time}</th>
			);
			for(var intIdxDate in reserve){
				//-- クラス設定
				var dtClassName = "td_reserve";
				if(reserve[intIdxDate].Detail[intIdx].Status == '休'){
					//- 休日
					if(reserve[0].Detail[intIdx].EndFlg != '1'){
						dtClassName = "td_reserve-holiday";
					}else{
						dtClassName = "reserve-end-time td_reserve-holiday";
					}
				}else if(reserve[intIdxDate].Detail[intIdx].OverDateFlg == '1') {
					//- 表示範囲外
					if(reserve[0].Detail[intIdx].EndFlg != '1'){
						dtClassName = "td_reserve-over";
					}else{
						dtClassName = "reserve-end-time td_reserve-over";
					}
				}else if(reserve[intIdxDate].Detail[intIdx].Status != '○') {
					//- 予約可以外
					if(reserve[0].Detail[intIdx].EndFlg != '1'){
						dtClassName = "td_reserve-ng";
					}else{
						dtClassName = "reserve-end-time td_reserve-ng";
					}
				}else{
					//- その他(予約可)
					if(reserve[0].Detail[intIdx].EndFlg != '1'){
						dtClassName = "td_reserve";
					}else{
						dtClassName = "reserve-end-time td_reserve";
					}
				}

				//--- Statusが「TEL」である場合に、変数にtrueを入れる
				if (reserve[intIdxDate].Detail[intIdx].Status === 'TEL') isTel = true

				//-- PC
				if(reserve[intIdxDate].Detail[intIdx].Status == '○') {
					//-- 予約可
					lstData.push(
						<td
							className={dtClassName}
							key={'dtl-' + intIdx + '-' + intIdxDate}
							onClick={handleOnSubmit}
							data-id={intIdx + '-' + intIdxDate}
						>
							<Button
								className="reserve-button"
								/*onClick={handleOnSubmit}*/
								data-id={intIdx + '-' + intIdxDate}
							>
								{reserve[intIdxDate].Detail[intIdx].Status}
							</Button>
							{reserve[intIdxDate].Detail[intIdx].ZanCnt != "" && (
								<>
								<br/>
								<label className={"reserve-zancnt"}>{reserve[intIdxDate].Detail[intIdx].ZanCnt}</label>
								</>
							)}
							<input type="hidden" id={'hdn-date-' + intIdx + '-' + intIdxDate} value={reserve[intIdxDate].Detail[intIdx].Date}></input>
							<input type="hidden" id={'hdn-time-' + intIdx + '-' + intIdxDate} value={reserve[intIdxDate].Detail[intIdx].Time}></input>
						</td>
					);
				}else{
					//-- 予約不可
					lstData.push(
						<td
							className={dtClassName}
							key={'dtl-' + intIdx + '-' + intIdxDate}
						>
							{reserve[intIdxDate].Detail[intIdx].Status === 'TEL' && shopTel !== '' ?
								<a
									className={"reserve-not-mark"}
									style={{color: '#909000', fontWeight: 'bold', textDecoration: 'underline'}}
									href={'tel:' + shopTel}
								>
									{reserve[intIdxDate].Detail[intIdx].Status}
								</a>
							:
								<label
									className={"reserve-not-mark"}
									style={{color: reserve[intIdxDate].Detail[intIdx].Status !== '-' ? (reserve[intIdxDate].Detail[intIdx].Status === 'TEL' ? '#909000' : '#b30000') : '#000000',
												fontWeight: reserve[intIdxDate].Detail[intIdx].Status === 'TEL' && 'bold'}}
								>
									{reserve[intIdxDate].Detail[intIdx].Status}
								</label>
							}
							{reserve[intIdxDate].Detail[intIdx].ZanCnt != "" && (
								<>
								<br/>
								<label className={"reserve-zancnt"}>{reserve[intIdxDate].Detail[intIdx].ZanCnt}</label>
								</>
							)}
							<input type="hidden" id={'hdn-date-' + intIdx + '-' + intIdxDate} value={reserve[intIdxDate].Detail[intIdx].Date}></input>
							<input type="hidden" id={'hdn-time-' + intIdx + '-' + intIdxDate} value={reserve[intIdxDate].Detail[intIdx].Time}></input>
						</td>
					);
				}

				//-- スマホ
				if(reserve[intIdxDate].Detail[intIdx].Status == '○') {
					//- 予約可
					lstDataSp.push(
						<td
							className={dtClassName + '-sp'}
							key={'dtl-sp-' + intIdx + '-' + intIdxDate}
							onClick={handleOnSubmit}
							data-id={intIdx + '-' + intIdxDate}
						>
							<Button
								className="sp-size reserve-button-sp"
								/*onClick={handleOnSubmit}*/
								data-id={intIdx + '-' + intIdxDate}>
								{reserve[intIdxDate].Detail[intIdx].Status}
							</Button>
							{reserve[intIdxDate].Detail[intIdx].ZanCnt != "" && (
								<>
								<br/>
								<label>{reserve[intIdxDate].Detail[intIdx].ZanCnt.replace('り：', '')}</label>
								</>
							)}
						</td>
					);
				}else{
					//- 予約不可
					lstDataSp.push(
						<td
							className={dtClassName + '-sp'}
							key={'dtl-sp-' + intIdx + '-' + intIdxDate}
						>
							{reserve[intIdxDate].Detail[intIdx].Status === 'TEL' && shopTel !== '' ?
								<a
									className="sp-size not-reserve-button-sp"
									data-id={intIdx + '-' + intIdxDate}
									style={{color: '#909000', fontWeight: 'bold', textDecoration: 'underline'}}
									href={'tel:' + shopTel}
									disabled={"disabled"}
								>
									TEL
								</a>
							:
								<Button
									className="sp-size not-reserve-button-sp"
									data-id={intIdx + '-' + intIdxDate}
									style={{color: reserve[intIdxDate].Detail[intIdx].Status !== '-' ? (reserve[intIdxDate].Detail[intIdx].Status === 'TEL' ? '#909000' : '#b30000') : '#000000',
											fontWeight: reserve[intIdxDate].Detail[intIdx].Status === 'TEL' && 'bold',
											width: reserve[intIdxDate].Detail[intIdx].Status === 'TEL' && '55%'}}
									disabled={"disabled"}
								>
									{reserve[intIdxDate].Detail[intIdx].Status}
								</Button>
							}
							{reserve[intIdxDate].Detail[intIdx].ZanCnt != "" && (
								<>
								<br/>
								<label>{reserve[intIdxDate].Detail[intIdx].ZanCnt.replace('り：', '')}</label>
								</>
							)}
						</td>
					);
				}
			}
			lstDetail.push(
				<tr style={{width:"100%"}} key={'row-' + intIdx}>
					{lstData}
				</tr>
			);
			lstDetailSp.push(
				<tr key={'row-' + intIdx}>
					{lstDataSp}
				</tr>
			);
		}

		if(lstHeader.length > 0){
			lstReserve.push(
				<thead className="pc-size list-top-thead" key="000">
					<tr>
						{lstHeader}
					</tr>
				</thead>
			);
			lstReserveSp.push(
				<thead className="sp-size list-top-thead" key="000">
					<tr>
						{lstHeaderSp}
					</tr>
				</thead>
			);
		}

		if(lstDetail.length > 0){
			lstReserve.push(
				<tbody className="pc-size list-pc-top-tbody" key={'001'}>
					{lstDetail}
				</tbody>
			);
			lstReserveSp.push(
				<tbody className="sp-size list-sp-top-tbody" key={'001'}>
					{lstDetailSp}
				</tbody>
			);
		}else{
			lstReserve.push(
				<tbody key={'001'}>
				</tbody>
			);
			lstReserveSp.push(
				<tbody className="list-tbody" key={'001'}>
				</tbody>
			);
		}

		if(lstReserve.length > 0){
			var lstReserveDisp = [];
			lstReserveDisp.push(
				<table className={"pc-table list-top"} key={'top-pc-01'}>
					{lstReserve}
				</table>
			);
			lstReserveDisp.push(
				<table className={"sp-table list-top"} key={'top-sp-01'}>
					{lstReserveSp}
				</table>
			);

			return(
				lstReserveDisp
			);
		}
	}

	/**
	 *　スタッフ選択
	 * @param e
	 */
	function fncOnClickStaff(e){
		//--- スタッフID取得
		const strID = e.currentTarget.getAttribute('data-id');
		var strStaffID = document.getElementById('hdn-staff-' + strID ).value;
		for(var intIdx in staff){
			document.getElementById('td-staff-' + intIdx).style.backgroundColor = '';
		}
		document.getElementById('td-staff-' + strID).style.backgroundColor = list_color;

		//--- スタッフID格納
		data.staff_id = strStaffID;
		data.week_kbn = '3';
		//-- 検索イベントを発生させる
		if (selstaff == strStaffID) setEvent(!event);
		setSelStaff(strStaffID);
	}

	/**
	 * コース選択
	 * @param e
	 */
	function fncOnClickCourse(e){
		//--- コースID取得
		const strID = e.currentTarget.getAttribute('data-id');
		var strCourseID = document.getElementById('hdn-course-' + strID ).value;
		for(var intIdx in course){
			document.getElementById('td-course-' + intIdx).style.backgroundColor = '';
		}
		document.getElementById('td-course-' + strID).style.backgroundColor = list_color;

		//--- コースID格納
		data.course_id = strCourseID;
		data.week_kbn = '3';
		//-- 検索イベントを発生させる
		if (selcourse == strCourseID) setEvent(!event);
		setSelCourse(strCourseID);
	}

	/**
	 * 前の週クリック
	 */
	function fncOnClickBefWeek(){
		//--- パラメータ設定
		data.week_kbn = '1';
		setCldMsg('');
		//-- 検索イベントを発生させる
		setEvent(!event);
	}

	/**
	 * 今週クリック
	 */
	function fncOnClickNowWeek(){
		//--- パラメータ設定
		data.week_kbn = '0';
		setCldMsg('');
		//-- 検索イベントを発生させる
		setEvent(!event);
	}

	/**
	 * 次の週クリック
	 */
	function fncOnClickNextWeek(){
		//--- パラメータ設定
		data.week_kbn = '2';
		setCldMsg('');
		//-- 検索イベントを発生させる
		setEvent(!event);
	}

	/**
	 * カレンダー日付クリック
	 */
	function fncOnClickCalendar(date){
		//--- パラメータ設定
		var strCalDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
		data.week_kbn = '9';
		var nowDate = new Date();
		setCldMsg('');
		setTopDate(date);
		var dispKikan = 6;
		if (data.disp_type == '1') {
			dispKikan = 2;
		}

		// クリックされた日付の判定
		if(date <= dtNow) {
			// 過去の場合、現在日付で再読み込み
			data.week_kbn = '10';
		} else if (date > nowDate.setDate(nowDate.getDate() + (7 * rsvWkCnt - 1))) {
			// 予約可能期間外の場合、現在日付で再読み込み＋エラーメッセージ表示
			data.week_kbn = '10';
			setCldMsg('予約可能期間('+ rsvWkCnt + '週間先まで)を超えています。');
		}
		data.top_date = strCalDate;
		//-- 検索イベントを発生させる
		setEvent(!event);
	}

	/**
	 * 予約選択
	 * @param e
	 */
	function handleOnSubmit(e) {
		const strID = e.currentTarget.getAttribute('data-id');
		var strDate = document.getElementById('hdn-date-' + strID ).value;
		var strTime = document.getElementById('hdn-time-' + strID ).value;
		data.reserve_date = strDate;
		data.reserve_time = strTime;
		data.id = dbconn.id;
		data.week_kbn = '3';
		e.preventDefault();
		onSubmitData(data);
	}

	/**
	 * モーダルクローズ
	 */
	function modalClose() {
	}

	return (
		<>
			<Form>
				<Row className="list-header">
					<Col md={{ span: 12}}>
						<p className="text-header disp-left-space-area">▼コースを選択</p>
					</Col>
				</Row>
				<Row className="justify-content-md-center disp-left-space-area disp-right-space-area">
					<Col md={{ span: 12}}>
						<table className={"course-list"}>
							{fncGetCourseList()}
						</table>
					</Col>
				</Row>

				<Row className="list-header">
					<Col md={{ span: 12}}>
						<p className="text-header disp-left-space-area">▼スタッフを選択</p>
					</Col>
				</Row>
				<Row className="justify-content-md-center disp-left-space-area disp-right-space-area">
					<Col md={{ span: 12}}>
						<table className={"staff-list"}>
							{fncGetStaffList()}
						</table>
					</Col>
				</Row>

				<Row className="list-header">
					<Col md={{ span: 12}}>
						<p className="text-header disp-left-space-area">▼予約日・時間を選択</p>
					</Col>
				</Row>
				<Row className="justify-content-md-center disp-left-space-area disp-right-space-area" style={{paddingBottom: 0}}>
					<label style={{color: "red"}}>{cldMsg}</label>
				</Row>
				<Row className="justify-content-md-center disp-left-space-area disp-right-space-area" style={{paddingTop: 0}}>
					<DatePicker
						locale="ja"
						selected={topDate}
						onChange={fncOnClickCalendar}
						customInput={
							<div>
								<label style={{fontSize: "1.2rem", paddingRight: "0.3rem"}}>{(data.reserve_date).replaceAll('-', '/')}</label>
								<a style={{fontSize: "2rem"}}>
									<IoIosCalendar/>
								</a>
							</div>
						}
					/>
				</Row>
				<Row className="justify-content-md-center disp-left-space-area disp-right-space-area">
					<Col className="pc" md={{ span: 2}}>
						<Button
							block
							className="week-button register sp-size"
							onClick={fncOnClickBefWeek}
							disabled={datebefflg == '1'? 'disabled' : ''}
						>
							＜ 前の週
						</Button>
					</Col>
					<Col className="sp" xs={{ span: 4}}>
						<Button
							block
							className="week-button register sp-size"
							onClick={fncOnClickBefWeek}
							disabled={datebefflg == '1'? 'disabled' : ''}
						>
							＜ 3日前
						</Button>
					</Col>
					<Col className="pc" md={{ span: 2}}>
						<Button
							block
							className="week-button register sp-size"
							onClick={fncOnClickNowWeek}
						>
							｜　今 週　｜
						</Button>
					</Col>
					<Col className="sp" xs={{ span: 4}}>
						<Button
							block
							className="week-button register sp-size"
							onClick={fncOnClickNowWeek}
						>
							｜本日｜
						</Button>
					</Col>
					<Col className="pc" md={{ span: 2}}>
						<Button

							className="week-button register sp-size"
							onClick={fncOnClickNextWeek}
							disabled={dateflg == '2'? 'disabled' : ''}
						>
							次の週 ＞
						</Button>
					</Col>
					<Col className="sp" xs={{ span: 4}}>
						<Button
							block
							className="week-button register sp-size"
							onClick={fncOnClickNextWeek}
							disabled={dateflg == '2'? 'disabled' : ''}
						>
							3日後 ＞
						</Button>
					</Col>
				</Row>
				{(data.before_date != '' || data.next_date != '') &&
				(
					<Row className="pc-flex justify-content-md-center row-over-msg">
						<Col className="pc" md={{ span: 5}}>
							{data.before_date != '' ?
								<label className={"label-over-left-msg register sp-size padding-right-10"}>
									{data.before_date}以前は「前の週」をクリック
								</label>
								:
								<></>
							}
						</Col>
						<Col className="pc" md={{ span: 2}}>
						</Col>
						<Col className="pc" md={{ span: 5}}>
							{data.next_date != '' ?
								<label className={"label-over-right-msg register sp-size padding-left-10"}>
									{data.next_date}以降は「次の週」をクリック
								</label>
								:
								<></>
							}
						</Col>
					</Row>
				)}

				{(data.before_date != '') && (
					<Row className="sp justify-content-md-center row-over-msg">
						<Col className="sp" xs={{ span: 12}}>
							{data.before_date != '' ?
								<label className={"label-over-right-msg register sp-size"}>
									{data.before_date}以前は「3日前」をクリック
								</label>
								:
								<></>
							}
						</Col>
					</Row>
				)}

				{(data.next_date != '') && (
					<Row className="sp justify-content-md-center row-over-msg">
						<Col className="sp" xs={{ span: 12}}>
							{data.next_date != '' ?
								<label className={"label-over-left-msg register sp-size"}>
									{data.next_date}以降は「3日後」をクリック
								</label>
								:
								<></>
							}
						</Col>
					</Row>
				)}

				<Row className="justify-content-md-center disp-left-space-area disp-right-space-area">
					<Col md={{ span: 12}}>
						{fncSetReserveList()}
					</Col>
				</Row>

				{isTel && (
					<Row className="justify-content-md-center disp-left-space-area disp-right-space-area row-over-msg">
						<Col className="" xs={{ span: 12}}>
							<label className="label-over-left-msg register sp-size pl-0" style={{color: '#909000', fontWeight: 'bold'}}>
								TELの場合は、直接お電話にてお問い合わせください。
							</label>
						</Col>
					</Row>
				)}

				<div className="error-credentials">			
					{(errors && errors.error == 'invalid_credentials')  ?  credentialError : errors.message }
				</div>	

			</Form>

			<Modal show={modalShow} onHide={modalClose}>
				<Modal.Body className="bdy-disp">
					<Row className="justify-content-md-center">
						<Col xs={{ span: 12}}>
							<label className="lbl-disp">表示中...</label>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		</>
	
	);
}

 export default TopForm;