import React, { useState, useEffect } from 'react';

import './RegistrationDetailForm.css';
import Http from 'Utils/Axios';
import Required from 'Component/Required/Required';
import usePost from 'Utils/EndPoints/usePost';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData';
import DatePicker from 'Utils/FormHandlers/DatePicker';
import CustomModal from 'Utils/Modal/CustomModal';
import { withRouter } from 'react-router-dom';
import { FormInput } from 'Utils/FormHandlers/HandleFormProperties';
import { Button, Col, Row, FormControl, Form } from 'react-bootstrap';
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";
import {APP_TOP, MSG_NG_USER_URL} from "../../../../Utils/Common/ComConst";
import * as AutoKana from 'vanilla-autokana';

const dtNow = new Date();

const defaultState = {
	user_id : "",
	password : "",
	confirm_password : "",
	skipped_password : "0",
	user_nm : "",
	user_kn : "",
	user_birth_date : "",
	birth_date : {
		'day' : '',
		'month' : '',
		'year' : '',
	},
	user_birthday_year : "",
	user_birthday_month : "",
	user_birthday_day : "",
	user_sex : "0",
	info_mailflg : "1",
	email : "",
	user_tel : "",
	login_pass : "",
	id : "",
	send_kbn : "2",
	line_id : "",
}

let autokana;
// モバイル起動の場合、電話の枠を広くする。
let default_tel_style = "input-user-update-tel";
if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
	default_tel_style = "input-user-update-mobile-tel";
}

function RegistrationDetailForm({ data, rawData, token, onSubmitDataForVerify, shop, user, dbconn, history, skipPW, line, loaing }) {
	const defaultData = Object.keys(data).length > 0 ? data : defaultState
	const [fieldData, handleDataFormat, setManualField] = useHandleFormData({ ...defaultData, token });
	const [result, submitPost, errors, setErrorClear] = usePost();
	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	//--- 誕生日が設定されない問題の対策
	const [initBirthDay, setInitBirthDay] = useState(false);

	/**
	 * 自動カナ入力
	 */
	useEffect(() => {
		handleNameInput = handleNameInput.bind(fieldData);
		autokana = AutoKana.bind('#user_nm', '#user_kn', { katakana: true });
	},[]);

	function handleNameInput(ev) {
		fieldData.user_nm = ev.target.value;
		fieldData.user_kn = autokana.getFurigana();
	}

	/**
	 * 初期処理
	 */
	 useEffect (() => {
		if (!rawData.error) {
			if (defaultData != defaultState) {
				//- データがある（確認からの戻る）
			} else if (rawData.results) {
				//- データがある（初期表示）
				SetField(rawData.results, user);
			} else if (user) {
				//- データがある（非メール認証）
				SetField(null, user);
			} else {
				//- データがない場合セキュリティフラグオフの新規登録者とみなし新規登録
				setManualField({
					...fieldData,
				});
			}
			setInitBirthDay(true);
		} else {
			setModalMsg(MSG_NG_USER_URL);
			setShow(true);
		}
	},[]);

	/**
	 * フィールドへデータセット
	 * @param {*} results response.data.results を指定してください。
	 * @param {*} user 会員情報
	 */
	function SetField(results, user) {
		if (user) {
			let email = '';
			if (!line) email = results ? results.email : user.email ? user.email : '';
			setManualField({
				...fieldData,
				user_id: user.user_id,
				password: skipPW ? 'ABCD9999' : '',
				confirm_password: skipPW ? 'ABCD9999' : '',
				skipped_password: skipPW ? '1' : '0',
				user_nm: user.user_nm,
				user_kn: user.user_kn,
				user_birth_date: ConcatDate(user.user_birthday_year, user.user_birthday_month, user.user_birthday_day),
				birth_date: {
					"day": user.user_birthday_day,
					"month": user.user_birthday_month,
					"year": user.user_birthday_year
				},
				user_birthday_year :  user.user_birthday_year,
				user_birthday_month : user.user_birthday_month,
				user_birthday_day : user.user_birthday_day,
				user_sex: user.user_sex,
				info_mailflg : '1',
				email: email,
				line_id: line && results ? results.line_id : '',
				user_tel: user.user_tel,
				login_pass : "",
				id : "",
			});
		} else {
			setManualField({
				...fieldData,
				email: !line && results ? results.email : '',
				line_id: line && results ? results.line_id : '',
			});
		}
	}

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		if (!show) return;
		setShow(false);
		let strPram = fncgSetUrlPram(dbconn);
		history.push(APP_TOP + strPram);
	}

	/**
	 *
	 * @param date
	 */
	function handleDate(date) {
		fieldData.id = dbconn.id;
		setManualField({
			...fieldData,
			birth_date: date,
		})
	}

	function ConcatDate(year, month, day) {
		let tmpDate = year + '-' + month + '-' + day;
		return tmpDate;
	}

	/**
	 *
	 * @param e
	 */
	function handelOnSubmit(e) {
		fieldData.user_birth_date = ConcatDate(fieldData.birth_date.year, fieldData.birth_date.month, fieldData.birth_date.day);
		fieldData.id = dbconn.id;
		e.preventDefault();
		setErrorClear();
		submitPost(line ? 'api/line/chk-user-update' : 'api/chk-user-update', {
			...fieldData,
		});
	}

	/**
	 * チェック結果エラー
	 */
	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			window.scrollTo(0, 0);
		}
	}, [errors])

	/**
	 * チェック結果正常
	 */
	useEffect(() => {
		if (Object.keys(result).length > 0) {
			onSubmitDataForVerify(fieldData);
		}
	}, [result]);

	return (
		<>
			{!line && (
			<>
				<div className="text-header first-header disp-left-space-area">
					▼ログイン情報
				</div>
				<Row className="registraion-form-row">
					<Required/> メールアドレス(例) xxx@xxx.co.jp
				</Row>
				<Row className="registraion-form-row">
					<FormControl
						className="registration-form-input-field"
						type="text"
						value={fieldData.email}
						placeholder="メールアドレスを入力してください"
						onChange={handleDataFormat}
						name="email"
						maxLength="255"
						{...FormInput}
					/>
				</Row>
				{errors.email && (
					<Row className="registraion-form-row">
						<span className="error-span">{errors.email[0]}</span>
					</Row>
				)}
				{!skipPW && (
					<>
					<Row className="registraion-form-row">				
						<Required/> パスワード(半角英数字の組み合わせで8文字以上20文字以内で記載してください)
					</Row>
					<Row className="registraion-form-row">
						<FormControl
							className="registration-form-input-field"
							type="password" 
							placeholder="パスワードを入力してください"
							value={fieldData.password}
							onChange={handleDataFormat}
							name="password"
							maxLength="20"
							{...FormInput}
						/>
					</Row>
					{errors.password && (
						<Row className="registraion-form-row">
							<span className="error-span">{errors.password[0]}</span>
						</Row>
					)}
					<Row className="registraion-form-row">		
						<Required/> パスワード確認(確認のため、もう一度入力してください)
					</Row>
					<Row className="registraion-form-row">
						<FormControl
							className="registration-form-input-field"
							type="password"
							placeholder="上記と同じパスワードを再入力してください"
							value={fieldData.confirm_password}
							onChange={handleDataFormat}
							name="confirm_password"
							maxLength="20"
							{...FormInput}
						/>
					</Row>
					{errors.confirm_password && (
						<Row className="registraion-form-row">
							<span className="error-span">{errors.confirm_password[0]}</span>
						</Row>
					)}
					</>
				)}
			</>
			)}
			<Form className="registration-form">
				<div className="text-header second-header disp-left-space-area">
					▼お客様情報
				</div>
				<Row className="registraion-form-row">				
					<Required/> お名前(例)山本 太郎
				</Row>
				<Row className="registraion-form-row">
					<FormControl
				    	className="registration-form-input-field"
					    type="text"
						value={fieldData.user_nm}
						onChange={handleDataFormat}
						onInput={handleNameInput}
					    name="user_nm"
						id="user_nm"
						maxLength="50"
					    {...FormInput}
				    />	
				</Row>
				{errors.user_nm && (
					<Row className="registraion-form-row">
						<span className="error-span">{errors.user_nm[0]}</span>
					</Row>
				)}
				<Row className="registraion-form-row">
					<Required/> フリガナ(カタカナで入力してください)(例) ヤマモト タロウ
				</Row>
				<Row className="registraion-form-row">
					<FormControl
				    	className="registration-form-input-field"
					    type="text"
						value={fieldData.user_kn}
					    onChange={handleDataFormat}
					    name="user_kn"
						id="user_kn"
						maxLength="50"
					    {...FormInput}
				    />
				</Row>
				{errors.user_kn && (
					<Row className="registraion-form-row">
						<span className="error-span">{errors.user_kn[0]}</span>
					</Row>
				)}
				<Row className="registraion-form-row">
					<Required/> 電話番号
				</Row>
				<Row className="registraion-form-row">
					<FormControl
						className={default_tel_style}
						type="text"
						value={fieldData.user_tel}
						onChange={handleDataFormat}
						name="user_tel"
						maxLength="15"
						{...FormInput}
					/>
				</Row>
				{errors.user_tel && (
					<Row className="registraion-form-row">
						<span className="error-span">{errors.user_tel[0]}</span>
					</Row>
				)}
				<Row className="registraion-form-row">
					<Required/> 性別
				</Row>
				<Row className="registraion-form-row">
					<label>
						<input
							className="registration-detail-radio"
							type="radio"
							name="user_sex"
							value="0"
							onChange={handleDataFormat}
							checked={fieldData.user_sex === "0"}

						/>男 性
					</label>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<label>
					<input
						className="registration-detail-radio"
						type="radio"
						name="user_sex"
						value="1"
						onChange={handleDataFormat}
						checked={fieldData.user_sex === "1"}
					/>女 性
					</label>
				</Row>
				{errors.user_sex && (
					<Row className="registraion-form-row">
						<span className="error-span">{errors.user_sex[0]}</span>
					</Row>
				)}
				<Row className="registraion-form-row">
					生年月日
				</Row>
				<Row className="registraion-form-row">
					{initBirthDay ? (
						<DatePicker date={handleDate} defaultData={fieldData.birth_date}/>
					):(
						<p></p>
					)}
				</Row>
				{errors.user_birth_date && (
					<Row className="registraion-form-row">
						<span className="error-span">{errors.user_birth_date[0]}</span>
					</Row>
				)}
				<Row className="registraion-form-row">
					{shop.shop_nm != undefined && shop.shop_nm != ''? shop.shop_nm + 'からの'　: ''}お知らせ{line ? 'メッセージ' : 'メール'}
				</Row>
				<Row className="registraion-form-row">
					<label>
						<input
							className="registration-detail-radio"
							type="radio"
							value="1"
							checked={fieldData.info_mailflg === "1"}
							onChange={handleDataFormat}
							name="info_mailflg"
						/>希望する
					</label>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<label>
						<input
							className="registration-detail-radio"
							type="radio"
							value="0"
							checked={fieldData.info_mailflg === "0"}
							onChange={handleDataFormat}
							name="info_mailflg"
						/>希望しない
					</label>
				</Row>
				{errors.info_mailflg && (
					<Row className="registraion-form-row">
						<span className="error-span">{errors.info_mailflg[0]}</span>
					</Row>
				)}
				<Row className="registraion-form-row">
					※「希望しない」を選択した場合でも、ご予約内容確認等の通知{line ? 'メッセージ' : 'メール'}は受け取ることが出来ます。
				</Row>

				<Row className="registration-button-container disp-left-space-area disp-right-space-area">
					<Col md={{ span: 4, offset: 8 }}>
						<Button
							block
							className="registration-button"
							onClick={handelOnSubmit}
							disabled={loaing}
						>
							登 録
						</Button>
					</Col>
				</Row>
			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>

		</>
	)
}

export default withRouter(RegistrationDetailForm);
