import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useConn, {IsDbconnId} from 'Utils/EndPoints/useConn';
import useUser from 'Utils/EndPoints/useUser';
import useShop from 'Utils/EndPoints/useShop';

import './Header.css';
import { withRouter } from 'react-router-dom'
import { Button, Col, Row } from 'react-bootstrap';
import MyPageLinks from "./components/MyPageLinks";
import {APP_TOP, PRM_ID} from "../../Utils/Common/ComConst";
import { IoIosCalendar, IoMdCalendar } from "react-icons/io";

function Header({ appdata }) {
	let history = useHistory();
	const [urlpram, dbconn, loadingConn] = useConn(appdata);
	const [user, setUser, loadingUser] = useUser(appdata);
	const [shop, setShop, loadingShop] = useShop(appdata, dbconn);

	const [start, setStart] = useState('0');

	/**
	 * URLパラメータ取得
	 */
	useEffect(() => {
		if (loadingConn || loadingUser || loadingShop) return;
		setStart('1');
	}, [loadingConn, loadingUser, loadingShop])

	/**
	 * TOP画面遷移
	 */
	function topDisp(){
		let top = APP_TOP + urlpram;
		if (top == history.location.pathname + history.location.search) {
			history.go(0);
		} else {
			history.push(top);
		}
	}

	/**
	 * 予約一覧遷移
	 */
	function reserveList(){
		history.push('/reserve-list' + urlpram);
	}

	/**
	 * 予約履歴一覧遷移
	 */
	function reserveHistory(){
		history.push('/reserve-history' + urlpram);
	}

	/**
	 * 会員情報変更遷移
	 */
	function userUpdate(){
		history.push('/user-update' + urlpram);
	}

	/**
	 * ログアウト
	 */
	function logout() {
		history.push('/back' + urlpram);
	}

	/**
	 * ログイン
	 * @constructor
	 */
	function HandleLogin(){
		history.push('/login' + urlpram);
	}

	const ShopLogo = () => {
		return (
			<>
			{
				(shop != undefined && shop != '')?
					(shop.header_color != null && shop.header_color != '')?
						<a className="a-header" href={(shop != undefined && shop != '') ? shop.shop_url : ''} >
							<img
								className="header-img"
								id="imgLogo"
								src={shop.header_logo}
							/>
						</a>
					:
					<></>
					:
					<></>
			}
			</>
		);
	}

	const ToppageLink = () => {
		return (
			<a className="a-page-top" style={{color:"#59A28B"}} onClick={topDisp}>　<IoIosCalendar className={"header-icon-logo"}/>オンライン予約</a>
		);
	}

	const LoginButton = () => {
		return (
			<span className="header-span-user-info">
				{user ?
					<MyPageLinks
						topDisp = {topDisp}
						reserveList = {reserveList}
						reserveHistory = {reserveHistory}
						userUpdate = {userUpdate}
						logout={logout}
						user={user}/>
				:
					<Button
						className="header-btn-login"
						onClick={HandleLogin}
					>
						ログイン
					</Button>
				}
			</span>
		);
	}

	const UserName = () => {
		return (
			<>{user && <span className="header-span-user-name">{user.user_nm} 様</span>}</>
		);
	}

	const HeaderRowStyle = () => {
		if (!shop || shop.header_color == null && shop.header_color == '') return {backgroundColor: "#FFFFFF"};
		return {backgroundColor: shop.header_color};
	};

	return (
		<>
			<div className="header" >
				{/** PC */}
				<Row className="header-row pc-header disp-left-space-area disp-right-space-area" style={HeaderRowStyle()}>
					<Col md={{ span: 8 }}>
						<span className="header-span">
							<ShopLogo />
							<ToppageLink />
						</span>
					</Col>
					<Col md={{ span: 4}}>
						<LoginButton />
						<UserName />
					</Col>
				</Row>
				{/** MOBILE */}
				<Row className="header-row-sp sp" style={HeaderRowStyle()}>
					<Col md={{ span: 12}}>
						<span className="header-span">
							<ShopLogo />
						</span>
					</Col>
				</Row>
				<Row className="header-row-title sp" style={HeaderRowStyle()}>
					<div className={"div-header-title"}>
						<span className="header-span-title">
							<ToppageLink />
						</span>
					</div>
					<div className={"div-header-login"}>
						<LoginButton />
					</div>
					<div className={"div-header-user"}>
						<UserName />
					</div>
				</Row>
			</div>
		</>	
	);
}

export default withRouter(Header);