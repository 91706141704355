import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useConn from 'Utils/EndPoints/useConn';

import './RegistrationComplete.css';
import { Button, Col, Row } from 'react-bootstrap';
import Layout from 'layouts/Default/Default';
import {APP_TOP, PRM_ID} from "../../../Utils/Common/ComConst";

function PasswordResetComplete({ appdata }) {
	let history = useHistory();
	const [ urlpram, dbconn, loadingConn ] = useConn(appdata);

	function handleSubmit() {
		history.push(APP_TOP + urlpram);
	}

	return (
		<>
			<Layout headerText="パスワードを再設定する。" appdata={appdata}>	
				<div className="password-reset-complete-label">
				   パスワードの再設定が完了しました
				</div>
				<Row className="justify-content-md-center disp-left-space-area disp-right-space-area">
					<Col md={6} >
						{!loadingConn && (
						<Button className="password-reset-complete-button" onClick={handleSubmit}>
							予 約 画 面 へ
						</Button>
						)}
					</Col>	
				</Row>
			</Layout >
		</>
	)
}

export default PasswordResetComplete;