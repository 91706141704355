import React, { useState, useEffect } from 'react';
import '../ReserveList.css';
import '../../../../Utils/Common/com-style.css';
import Http from 'Utils/Axios';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData'
import {Button, Col, Form, Row} from "react-bootstrap";

const defaultState = {
    reserve_id : "",
    id : "",
}

function ReserveListForm({user, onSubmitData, dbconn}){
    const [data] = useHandleFormData(defaultState);
    const [reserve, setReserve] = useState('');

    /**
     * 会員情報取得
     */
    useEffect(() => {
        if(user == undefined) return;
        if(user.user_id == undefined) return;
        let aryData = {
            user_id : user.user_id,
            id : dbconn.id,
        };
        Http.post('api/auth/get-reserve-list',{
            ...aryData
        }).then(response => {
            setReserve(response.data);
        });
    }, [user])

    /**
     * 予約状況一覧作成
     * @returns {*}
     */
    function fncGetReserveList(){
        //--- 予約情報取得
        if(reserve == '') return;

        //--- ヘッダー
        var lstHeader = [];
        var lstHeaderSp = [];
        lstHeader.push(
            <thead className="sp-size" key="000">
                <tr key={'hdr'}>
                    <th className="th-reserve-dtime" key={'hdr-time'}>予約日時</th>
                    <th className="th-reserve-info" key={'hdr-inf'}>予約内容</th>
                    <th className="th-reserve-cancel" key={'hdr-cnl'}></th>
                </tr>
            </thead>
        );
        lstHeaderSp.push(
            <thead className="sp-size list-reserve-thead" key="000">
                <tr key={'hdr'}>
                    <th className="th-reserve-dtime-sp" key={'hdr-time'}>予約日時</th>
                    <th className="th-reserve-info-sp" key={'hdr-inf'}>予約内容</th>
                    <th className="th-reserve-cancel" key={'hdr-cnl'}></th>
                </tr>
            </thead>
        );

        //--- 明細
        var lstReserve = [];
        var lstReserveSp = [];
        for(var intIdx in reserve){
            lstReserve.push(
                <tr className={"reserve-list-tr"} key={intIdx}>
                    <td className={"td-reserve-dtime"} key={'time-' + intIdx}>
                        <label>{reserve[intIdx].reserve_date}</label>
                        <br/>
                        <label>{reserve[intIdx].course_start_time}</label>
                    </td>
                    <td className={"td-reserve-info"} key={'inf-' + intIdx}>
                        <label>スタッフ：{reserve[intIdx].staff_nm != null? reserve[intIdx].staff_nm : ''}</label>
                        <br/>
                        <label>コース　：{ + reserve[intIdx].course_nm != null? reserve[intIdx].course_nm : ''}</label>
                        <br/>
                        <label>備考　　：{reserve[intIdx].reserve_note != null? reserve[intIdx].reserve_note : ''}</label>
                    </td>
                    <td className={"td-reserve-cancel"} key={'cnl-' + intIdx}>
                        <Button className="reserve-cancel-button" onClick={fncOnClickCancel} data-id={reserve[intIdx].id} >キャンセル</Button>
                        <input type="hidden" id={'hdn-reserve-' + reserve[intIdx].id} value={reserve[intIdx].reserve_id}></input>
                    </td>
                </tr>
            );

            lstReserveSp.push(
                <tr className={"reserve-list-tr"} key={intIdx}>
                    <td className={"td-reserve-dtime"} key={'time-' + intIdx}>
                        <label>{reserve[intIdx].reserve_date.split('(')[0].replace('年', '/').replace('月', '/').replace('日', '')}</label>
                        <br/>
                        <label>{'(' + reserve[intIdx].reserve_date.split('(')[1]}</label>
                        <br/>
                        <label>{reserve[intIdx].course_start_time}</label>
                    </td>
                    <td className={"td-reserve-info"} key={'inf-' + intIdx}>
                        <label>スタッフ：{reserve[intIdx].staff_nm != null? reserve[intIdx].staff_nm : ''}</label>
                        <br/>
                        <label>コース　：{ + reserve[intIdx].course_nm != null? reserve[intIdx].course_nm : ''}</label>
                        <br/>
                        <label>備考　　：{reserve[intIdx].reserve_note != null? reserve[intIdx].reserve_note : ''}</label>
                    </td>
                    <td className={"td-reserve-cancel"} key={'cnl-' + intIdx}>
                        <Button className="reserve-cancel-button" onClick={fncOnClickCancel} data-id={reserve[intIdx].id} >×</Button>
                        <input type="hidden" id={'hdn-reserve-' + reserve[intIdx].id} value={reserve[intIdx].reserve_id}></input>
                    </td>
                </tr>
            );
        }

        var lstThead = [];
        var lstTheadSp = [];
        lstThead.push(lstHeader);
        lstTheadSp.push(lstHeaderSp);

        var lstTbody = [];
        var lstTbodySp = [];
        if(lstReserve.length > 0){
            lstTbody.push(
                <tbody className="sp-size" key="001">
                    {lstReserve}
                </tbody>
            );
            lstTbodySp.push(
                <tbody className="sp-size list-reserve-tbody" key="002">
                    {lstReserveSp}
                </tbody>
            );
        }else{
            lstTbody.push(
                <tbody className="sp-size" key="001">
                </tbody>
            );
            lstTbodySp.push(
                <tbody className="sp-size list-reserve-tbody" key="002">
                </tbody>
            );
        }

        var lstTblReserve = [];
        lstTblReserve.push(
            <table className="pc-table list-reserve" key={"lst-pc-01"}>
                {lstThead}
                {lstTbody}
            </table>
        );
        lstTblReserve.push(
            <table className="sp-table list-reserve" key={"lst-sp-01"}>
                {lstTheadSp}
                {lstTbodySp}
            </table>
        );

        return(
            lstTblReserve
        );
    }

    /**
     * キャンセル選択
     * @param e
     */
    function fncOnClickCancel(e){
        const strID = e.currentTarget.getAttribute('data-id');
        var strReserveID = document.getElementById('hdn-reserve-' + strID ).value;
        data.reserve_id = strReserveID;
        data.id = dbconn.id;
        e.preventDefault();
        onSubmitData(data);
    }

    return (
        <>
            <Form >
                <Row className="justify-content-md-center">
                    <Col md={{ span: 12}}>
                        <p className="text-header disp-left-space-area">▼現在ご予約の内容一覧</p>
                    </Col>
                </Row>
                <Row className="justify-content-md-center disp-left-space-area disp-right-space-area">
                    <Col md={{ span: 12}}>
                        {fncGetReserveList()}
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default ReserveListForm;
