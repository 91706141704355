import {fncgGetID} from "./Common/ComCom";

const Auth = new class {
    constructor() {
        this.token_key = 'access_token_user';
        this.id = function() { 
            return fncgGetID();
        };

        this.storage = {};
        this.load();
    }

    save() {
        let dat = JSON.stringify({...this.storage});
        try {
            localStorage.setItem(this.token_key, dat);
        } catch(e) {
            global.access_token = dat;
        }
    }

    load() {
        let dat;
        try {
            dat = localStorage.getItem(this.token_key);
        } catch(e) {
            dat = global.access_token;
        }
        let json = JSON.parse(dat);
        if (json) this.storage = json;
    }

    /**
     * トークンを設定します。
     */
    SetAccessToken(token) {
        this.load();
        this.storage[this.id()] = token;
        this.save();
        // 未使用領域の削除
        try {
            localStorage.removeItem('dummy');
            localStorage.removeItem('test');
        } catch(e) {

        }
    }
    
    /**
     * トークンを取得します。
     */
    GetAccessToken() {
        if (!this.storage || !this.storage[this.id()]) return '';
        return this.storage[this.id()];
    }
    
    /**
     * トークン領域をクリアします。
     * 個別にトークンを削除したい場合はSetAccessTokenを利用してください。
     */
    InitAccessToken() {
        try {
            localStorage.removeItem(this.token_key);
        } catch(e) {

        }
        delete global.access_token;
        this.storage = {};
    }

    /**
     * トークンを所持しているか確認します。
     */
    HasToken() {
        return this.GetAccessToken() != '';
    }
}

export function IsOlderSettings() {
    try {
        let id = localStorage.getItem('dummy');
        let pw = localStorage.getItem('test');
        return (id && pw) ? true : false;
    } catch(e) {
        return false;
    }
}

export function GetOlderSettingID() {
    try {
        return localStorage.getItem('dummy');
    } catch(e) {
        return '';
    }
}

export function GetOlderSettingPW() {
    try {
        return localStorage.getItem('test');
    } catch(e) {
        return '';
    }
}

export default Auth;