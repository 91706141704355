import React from 'react';

import './ReserveCancelComplete.css';
import Layout from 'layouts/Default/Default';
import { Button, Col, Row } from 'react-bootstrap';

const ReserveCancelComplete = ({ data, onSubmitListBack　}) => {
	function handleListPage() {
		onSubmitListBack();
	}

	return (
		<>
			<Layout headerText="キャンセル受付">
				<Col md={{span:6, offset:3}} >
					<div className="reserve-label">
						<p className="mb-0" style={{whiteSpace: 'pre-line'}}>
							{data.disp === '0' && 'キャンセルを受け付けました。'}
							{data.disp === '1' && '予約締切時間を超えている為、キャンセル出来ません。 \n キャンセルを希望される場合は店舗側に直接連絡をお願いします。'}
							{data.error && '既に予約が削除されています。'}
						</p>
					</div>
				</Col>

				<Row className="justify-content-md-center">
					<Col md={6} >
						<Button className="reserve-complete-button" onClick={handleListPage}>
							予約一覧に戻る
						</Button>
					</Col>
				</Row>
			</Layout >
		</>
	)
}

export default ReserveCancelComplete;