import React, { useState, useEffect } from 'react';

import '../LineLogin.css';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData'
import { FormInput } from 'Utils/FormHandlers/HandleFormProperties'
import { Button, Container, Col, Row, InputGroup, FormControl, Form } from 'react-bootstrap';

const defaultState = {
	password : "",
	username : "",
	id : "",
}


function LineLoginForm({ onSubmitData, errors, loading, match, dbconn }) {
	const [fieldData, handleDataFormat] = useHandleFormData(defaultState);
	const credentialError = 'メールアドレスまたはパスワードが正しくありません。';

	function handleOnSubmit(e) {
		e.preventDefault();
		fieldData.id = dbconn.id;
	 	onSubmitData(fieldData);
	}

	return (
		<>
			<Form onSubmit={handleOnSubmit}>
				<Row className="justify-content-md-center disp-left-space-area disp-right-space-area">
					<Col md={3} >
				    	<label className="login-input-label">
							メールアドレス
						</label>
					</Col>
					<Col md={6}>
				    	<FormControl
					    	className="login-input-field"
						    type="text"
						    placeholder="登録したメールアドレスを入力してください"
							value={fieldData.username}
							onChange={handleDataFormat}
						    name="username"
							maxLength="255"
						    {...FormInput}
						    required
					    />	
					</Col>
				</Row>
				<Row className="justify-content-md-center disp-left-space-area disp-right-space-area">
					<Col md={3} >
				   		<label className="login-input-label">
							パスワード
						</label>
					</Col>
					<Col md={6}>
						 <FormControl
							className="login-input-field"
						    type="password" 
						    placeholder="登録したパスワードを入力してください"
						    onChange={handleDataFormat}
						    name="password"
							maxLength="255"
						    {...FormInput}
						    required
						/>	
					</Col>						
				</Row>
				<div className="error-login disp-left-space-area disp-right-space-area">
					{(errors && errors.error == 'invalid_credentials')  ?
						credentialError
						:
						errors.message
					}
				</div>	
				<Row className="justify-content-md-center disp-left-space-area disp-right-space-area">
					<Col md={6} className="login-input-label">				
						<Button className="login-button login" type="submit" disabled={loading}>
							{loading ? '読み込み中。 。 。' : 'ロ グ イ ン'}
						</Button>
					</Col>	
				</Row>
			</Form>		
		</>
	
	);
}

 export default LineLoginForm;