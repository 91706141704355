import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useConn from 'Utils/EndPoints/useConn';
import useShop from '../../Utils/EndPoints/useShop';
import {APP_VER} from 'Utils/version';

import './Footer.css';

function Footer({ appdata }){
	const [urlpram, dbconn, loadingConn] = useConn(appdata);
	const [shop, setShop, loadingShop] = useShop(appdata, dbconn);

	const [start, setStart] = useState('0');

	/**
	 * URLパラメータ取得
	 */
	useEffect(() => {
		if (loadingConn || loadingShop) return;
		setStart('1');
	}, [loadingConn, loadingShop])

	return (
		<>
			<div className="footer">
				<div className="footer-text">
					{shop ? <a href={shop.shop_url}>{shop.shop_nm}のホームページに戻る</a> : <a></a>}
				</div>
				<div className="footer-text">
					(c) DIGIT SYSTEM All Rights Reserved.
				</div>
				<div className="footer-version">
					version {APP_VER}
				</div>
			</div>
		</>
	);

}

export default Footer;