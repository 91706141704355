export const PRM_ID = 'id';
export const PRM_MODE = 'mode';
export const APP_TOP = '/';
export const MODE_NEW = '&mode=0';
export const MODE_TMP = '&mode=1';

export const THREAD_REG = 'Registration';

export const MSG_CMP_ADD = '登録が完了しました。';
export const MSG_CMP_UPD = '編集が完了しました。';
export const MSG_CMP_DEL = '削除が完了しました。';

export const MSG_CMP_RES_CAN = '予約のキャンセルが完了しました。';

export const MSG_NG_ADD = '登録に失敗しました。\n';
export const MSG_NG_UPD = '編集に失敗しました。\n';
export const MSG_NG_DEL = '削除に失敗しました。\n';

export const MSG_NG_RES_CAN = '予約のキャンセルに失敗しました。';
export const MSG_NG_NUM = '数字を入力してください。';
export const MSG_NOT_DATA = '該当データが存在しません。';
export const MSG_NG_TIME = '時間の入力に誤りがありあます。 \n※From < Toで入力してください。\n※時間1 < 時間2 < 時間3 < 時間4で入力してください。';

export const MSG_CMP_SEND_MAIL = 'メールの送信が完了しました。';
export const MSG_NG_SEND_MAIL = 'メールの送信に失敗しました。';

export const MSG_NG_USER_URL = 'ご利用のURLの有効期限が切れている可能性があります。';

export const MSG_NG_USER_ADD = '会員登録が正常に完了しませんでした。\n再度、会員登録をやり直してください。';