import React, { useState } from 'react';

import './ReserveFormVerify.css';
import '../../../Utils/Common/com-style.css';
import {withRouter} from 'react-router-dom'
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData'
import {Button, Col, Row, Form} from 'react-bootstrap';

const defaultState = {
	user_id : "",
	user_nm : "",
	staff_id : "",
	staff_nm : "",
	course_id : "",
	course_nm : "",
	course_time : "",
	reserve_date : "",
	reserve_date_disp : "",
	reserve_time : "",
	move_flg : "",
	reserve_note : "",
	id : "",
}

function ReserveFormVerify({ data, history, returnReserveForm, onSubmitVerify, error, dbconn }) {
	const [fieldData, handleDataFormat] = useHandleFormData(defaultState);
	const [loading, setLoading] = useState('0');

	function handleSubmit(e){
		setLoading('1');
		fieldData.user_id = data.user_id;
		fieldData.user_nm = data.user_nm;
		fieldData.staff_id = data.staff_id;
		fieldData.staff_nm = data.staff_nm;
		fieldData.course_id = data.course_id;
		fieldData.course_nm = data.course_nm;
		fieldData.course_time = data.course_time;
		fieldData.reserve_date = data.reserve_date;
		fieldData.reserve_date_disp = data.reserve_date_disp;
		fieldData.reserve_time = data.reserve_time;
		fieldData.move_flg = data.move_flg;
		fieldData.id = dbconn.id;
		e.preventDefault();
		onSubmitVerify(fieldData);
		//setLoading('0');
	}

	return (
		<>		
			<Form className="reserve-form-verify" onSubmit={handleSubmit}>
				<div className="text-header first-header disp-left-space-area">
					▼予約
				</div>
				<Row className="reserve-form-row">			
					<Row className="reserve-verify-value">
						予約者
					</Row>
					<Row className="reserve-verify-value">
						{data.user_nm}様
					</Row>	
				</Row>
				<Row className="reserve-form-row">
					<Row className="reserve-verify-value">
						予約日
					</Row>
					<Row className="reserve-verify-value">
						{data.reserve_date_disp}
					</Row>
				</Row>
				<Row className="reserve-form-row">
					<Row className="reserve-verify-value">
						時間
					</Row>
					<Row className="reserve-verify-value">
						{data.reserve_time} ～
					</Row>	
				</Row>
				<Row className="reserve-form-row">		
					<Row className="reserve-verify-value">
						スタッフ
					</Row>
					<Row className="reserve-verify-value">
						{data.staff_nm}
					</Row>	
				</Row>
				<Row className="reserve-form-row">		
					<Row className="reserve-verify-value">
						コース
					</Row>
					<Row className="reserve-verify-value">
						{data.course_nm}
					 </Row>
				</Row>

				<div className="text-header first-header disp-left-space-area">
					▼ご要望等
				</div>

				<Row className="reserve-form-row">
					<Row className="reserve-verify-value">
						予約についてご要望等があれば、以下にご記入ください。
					</Row>
					<Row className="reserve-verify-value">
						<textarea
							className="reserve-verify-input-field"
							cols="100"
							rows="4"
							value={fieldData.reserve_note}
							onChange={handleDataFormat}
							name="reserve_note"
							maxLength="500"
						/>
					</Row>
				</Row>

				<Row className="justify-content-md-center">
					<Col className="pc" md={4}>
						<Button
							className="btn-back"
							onClick={returnReserveForm}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
					<Col className="pc" md={4}>
						<Button
							className="reserve-verify-button"
							type="submit"
							variant="secondary"
							disabled={loading === '1'? 'disabled' : ''}
						>
							{loading === '1' ? 'ローディング中...' : '予約'}
						</Button>
					</Col>
					<Col className="sp" xs={4}>
						<Button
							className="btn-back"
							onClick={returnReserveForm}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
					<Col className="sp" xs={{ span: 4, offset: 4 }}>
						<Button
							className="reserve-verify-button"
							type="submit"
							variant="secondary"
							disabled={loading == '1'? 'disabled' : ''}
						>
							{loading == '1' ? 'ローディング中...' : '予約'}
						</Button>
					</Col>
				</Row>
			</Form>
		</>
	)
}

export default withRouter(ReserveFormVerify);