import React, { useState, useEffect } from 'react';

import '../../../../Utils/Common/com-style.css';
import Http from 'Utils/Axios';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData'
import {Button, Col, Form, Row} from "react-bootstrap";

const defaultState = {
    user_id : "",
    data_flg : "1",
    id : "",
};
function ReserveHistoryForm({user, dbconn}){
    const [fieldData] = useHandleFormData({ ...defaultState});
    const [reserve, setReserve] = useState('');

    useEffect(() => {
        if(user == undefined) return;
        if(user.user_id == undefined) return;
        fieldData.user_id = user.user_id;
        fieldData.id = dbconn.id;
        Http.post('api/auth/get-reserve-history',
            {...fieldData}
            ).then(response => {
            setReserve(response.data);
        });
    }, [user])

    function fncOnChangeList(e){
        fieldData.data_flg = e.target.value;
        fieldData.id = dbconn.id;
        Http.post('api/auth/get-reserve-history',
            {...fieldData}
        ).then(response => {
            setReserve(response.data);
        });
    }

    /**
     * 予約状況一覧作成
     * @returns {*}
     */
    function fncGetReserveList(){
        //--- 予約情報取得
        if(user == '') return;

        //--- ヘッダー
        var lstHeader  = [];
        var lstHeaderSp  = [];
        lstHeader.push(
            <thead　className="sp-size" key="000">
                <tr key={'hdr'}>
                    <th className={"th-history-dtime"} key={'hdr-time'}>予約日時</th>
                    <th className={"th-history-info"} key={'hdr-inf'}>予約内容</th>
                </tr>
            </thead>
        );
        lstHeaderSp.push(
            <thead　className="sp-size list-history-thead" key="000">
                <tr key={'hdr'}>
                    <th className={"th-history-dtime"} key={'hdr-time'}>予約日時</th>
                    <th className={"th-history-info"} key={'hdr-inf'}>予約内容</th>
                </tr>
            </thead>
        );

        var lstReserve = [];
        var lstReserveSp = [];
        for(var intIdx in reserve){
            lstReserve.push(
                <tr className={"reserve-history-tr"} key={intIdx}>
                    <td className={"td-history-dtime"} key={'time-' + intIdx}>
                        <label>{reserve[intIdx].reserve_date}</label>
                        <br/>
                        <label>{reserve[intIdx].course_start_time}</label>
                    </td>
                    <td className={"td-history-info"} key={'inf-' + intIdx}>
                        <label>スタッフ：{reserve[intIdx].staff_nm != null? reserve[intIdx].staff_nm : ''}</label>
                        <br/>
                        <label>コース　：{reserve[intIdx].course_nm != null? reserve[intIdx].course_nm : ''}</label>
                        <br/>
                        <label>ご要望　：{reserve[intIdx].reserve_note != null? reserve[intIdx].reserve_note : ''}</label>
                        <br/>
                        <label>状態　　：{reserve[intIdx].reserve_status == '0'? '予約中' :
                                         reserve[intIdx].reserve_status == '1'? '来院' :
                                         reserve[intIdx].reserve_status == '2'? 'キャンセル' :
                                         reserve[intIdx].reserve_status == '3'? 'キャンセル' :
                                         reserve[intIdx].reserve_status == '4'? '来院' : ''}</label>
                    </td>
                </tr>
            );
            lstReserveSp.push(
                <tr className={"reserve-history-tr"} key={intIdx}>
                    <td className={"td-history-dtime"} key={'time-' + intIdx}>
                        <label>{reserve[intIdx].reserve_date.split('(')[0].replace('年', '/').replace('月', '/').replace('日', '')}</label>
                        <br/>
                        <label>{'(' + reserve[intIdx].reserve_date.split('(')[1]}</label>
                        <br/>
                        <label>{reserve[intIdx].course_start_time}</label>
                    </td>
                    <td className={"td-history-info"} key={'inf-' + intIdx}>
                        <label>スタッフ：{reserve[intIdx].staff_nm != null? reserve[intIdx].staff_nm : ''}</label>
                        <br/>
                        <label>コース　：{reserve[intIdx].course_nm != null? reserve[intIdx].course_nm : ''}</label>
                        <br/>
                        <label>ご要望　：{reserve[intIdx].reserve_note != null? reserve[intIdx].reserve_note : ''}</label>
                        <br/>
                        <label>状態　　：{reserve[intIdx].reserve_status == '0'? '予約中' :
                                        reserve[intIdx].reserve_status == '1'? '来院' :
                                        reserve[intIdx].reserve_status == '2'? 'ｷｬﾝｾﾙ' :
                                        reserve[intIdx].reserve_status == '3'? 'ｷｬﾝｾﾙ' :
                                        reserve[intIdx].reserve_status == '4'? '来院' : ''}</label>
                    </td>
                </tr>
            );
        }

        var lstThead = [];
        var lstTheadSp = [];
        lstThead.push(lstHeader);
        lstTheadSp.push(lstHeaderSp);

        var lstTbody = [];
        var lstTbodySp = [];
        if(lstReserve.length > 0){
            lstTbody.push(
                <tbody className="sp-size" key="001">
                    {lstReserve}
                </tbody>
            );
            lstTbodySp.push(
                <tbody className="sp-size list-history-tbody" key="002">
                    {lstReserveSp}
                </tbody>
            );
        }else{
            lstTbody.push(
                <tbody className="sp-size"  key="001">
                </tbody>
            );
            lstTbodySp.push(
                <tbody className="sp-size list-history-tbody" key="002">
                </tbody>
            );
        }

        var lstTblReserve = [];
        lstTblReserve.push(
            <table className="pc-table reserve-history" key="res-pc-01">
                {lstThead}
                {lstTbody}
            </table>
        );
        lstTblReserve.push(
            <table className="sp-table reserve-history" key="res-sp-01">
                {lstTheadSp}
                {lstTbodySp}
            </table>
        );

        return(
            lstTblReserve
        );
    }

    return (
        <>
            <Form >
                <Row className="justify-content-md-center">
                    <Col md={{ span: 12}}>
                        <p className="text-header disp-left-space-area">▼ご予約の履歴一覧</p>
                    </Col>
                </Row>
                <Row className="row-reserve-history disp-left-space-area disp-right-space-area">
                    <Col className="pc" md={{ span: 2, offset: 8}}>
                        <div className="lbl-disp-order">
                            <label>表示期間</label>
                        </div>
                    </Col>
                    <Col className="pc" md={{ span: 2}}>
                        <Form.Control
                            as="select"
                            className="sel-data-list"
                            name='data_flg'
                            onChange={fncOnChangeList}
                        >
                            <option value="1" key="1">直近1ヶ月</option>
                            <option value="3" key="3">直近3ヶ月</option>
                            <option value="6" key="6">直近6ヶ月</option>
                            <option value="12" key="12">直近1年</option>
                        </Form.Control>
                    </Col>

                    <Col className="sp" xs={{ span: 4}}>
                        <div className="lbl-disp-order">
                            <label>表示期間</label>
                        </div>
                    </Col>
                    <Col className="sp" xs={{ span: 6, offset: 1}}>
                        <Form.Control
                            as="select"
                            className="sel-data-list"
                            name='data_flg'
                            onChange={fncOnChangeList}
                        >
                            <option value="1" key="1">直近1ヶ月</option>
                            <option value="3" key="3">直近3ヶ月</option>
                            <option value="6" key="6">直近6ヶ月</option>
                            <option value="12" key="12">直近1年</option>
                        </Form.Control>
                    </Col>
                </Row>
                <Row className="justify-content-md-center disp-left-space-area disp-right-space-area">
                    <Col md={{ span: 12}}>
                       {fncGetReserveList()}
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default ReserveHistoryForm;