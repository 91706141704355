import React, { useState, useEffect } from 'react';

import useHandleFormData from 'Utils/FormHandlers/useHandleFormData'
import { FormInput } from 'Utils/FormHandlers/HandleFormProperties'
import { Button, Container, Col, Row, InputGroup, FormControl, Form } from 'react-bootstrap';


const defaultState = {
	value : "",
    id : "",
}

function ForgorPasswordForm({ onSubmitData, errors, isLoading, match, dbconn }) {
    const [fieldData, handleDataFormat] = useHandleFormData(defaultState);

    function handleOnSubmit(e) {
        e.preventDefault();
        fieldData.id = dbconn.id;
        onSubmitData(fieldData);
    }

    return (
        <>
            <Form onSubmit={handleOnSubmit}>
                <Row className="justify-content-md-center disp-left-space-area disp-right-space-area" >
                    <Col>				
				    	<label>
                            メールアドレス
						</label>
                        <Row>
                            <FormControl
                                className="forgot-password-input-field"
                                placeholder="登録したメールアドレスを入力してください"
                                value={fieldData.value}
                                onChange={handleDataFormat}
                                name="value"
                                maxLength="255"
                                {...FormInput}
                                />
                        </Row>
					</Col>
                </Row>
                <div className="error-credentials disp-left-space-area disp-right-space-area">
                    {errors && errors.value && (
				    	<span className="error-span">{errors.value[0]}</span>
				   	)}
                </div>	
                 <Row className="justify-content-md-center disp-left-space-area disp-right-space-area">
                   <Col md={6} className="forgot-password-label">
                        <Button
                            className="forgot-password password"
                            type="submit"
                            disabled={isLoading == true? 'disabled' : ''}
                        >
                            {isLoading ==true? '読み込み中。 。 。' : '送信'}
                        </Button>
                   </Col>
                </Row> 
            </Form>
        </>
    );
}

export default ForgorPasswordForm;