import React, { useState, useEffect } from 'react';

import '../UserUpdate.css';
import Http from 'Utils/Axios';
import Required from 'Component/Required/Required';
import usePost from 'Utils/EndPoints/usePost';
import DatePicker from 'Utils/FormHandlers/DatePicker';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData';
import { FormInput } from 'Utils/FormHandlers/HandleFormProperties';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row, FormControl, Form } from 'react-bootstrap';
import * as AutoKana from 'vanilla-autokana';

const dtNow = new Date();

const defaultState = {
	user_id : "",
	password : "********",
	confirm_password : "********",
	login_pass : "",
	user_nm : "",
	user_kn : "",
	user_birth_date : "",
	birth_date : {
		'day' : '01',
		'month' : '01',
		'year' : dtNow.getFullYear() - 30,
	},
	user_birthday_year : "",
	user_birthday_month : "",
	user_birthday_day : "",
	user_sex : "",
	info_mailflg : "",
	email : "",
	user_tel : "",
	id : "",
	line_id : "",
	send_kbn : "",
}

let autokana;

function UserUpdateForm({ data, shop, dbconn, onSubmitDataForVerify }) {
    const defaultData = defaultState;
	if(Object.keys(data).length > 0 ){
		defaultData.user_id = data.user_id;
		defaultData.user_nm = data.user_nm;
		defaultData.user_kn = data.user_kn;
		defaultData.birth_date.day = data.user_birthday_day;
		defaultData.birth_date.month = data.user_birthday_month;
		defaultData.birth_date.year = data.user_birthday_year;
		defaultData.user_birth_date = data.user_birthday_year + '-' + data.user_birthday_month + '-' + data.user_birthday_day;
		defaultData.user_sex = data.user_sex;
		defaultData.info_mailflg = data.info_mailflg;
		defaultData.email = data.email;
		defaultData.password = 'ABCD9999';
		defaultData.confirm_password = 'ABCD9999';
		defaultData.login_pass = data.login_pass;
		defaultData.user_tel = data.user_tel;
		defaultData.id = dbconn.id;
		defaultData.send_kbn = data.send_kbn != null && data.send_kbn != '' ? data.send_kbn : '1';
		defaultData.line_id = data.line_id;
	}
	const [fieldData, handleDataFormat, setManualField] = useHandleFormData({ ...defaultData});
	const [result, submitPost, errors, setErrorClear] = usePost();

	let default_style = "registraion-form-row";
	let default_header_style = "registraion-form-row";
	let passInfo_header_style = "text-header first-header disp-left-space-area";
	let line_style = "display-none";
	let default_tel_style = "input-user-update-tel";
	// Line連携済み　かつ　メール情報ない場合、パスワード情報表示しない
	if (defaultData.line_id != null && defaultData.line_id != ""
			&& (defaultData.email == null || defaultData.email == "")) {
		default_style = "display-none";
		passInfo_header_style = "display-none";
		default_header_style = "display-none";
	}
	// Line連携済み　かつ　メール情報ある場合、パスワード情報する
	if (defaultData.line_id != null && defaultData.line_id != ""
		&& defaultData.email != null && defaultData.email != "") {
		line_style = "registraion-form-row";
		default_header_style = "display-none";
	}
	// モバイル起動の場合、電話の枠を広くする。
	if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
		default_tel_style = "input-user-update-mobile-tel";
	}

	/**
	 * 自動カナ入力
	 */
	useEffect(() => {
		handleNameInput = handleNameInput.bind(fieldData);
		autokana = AutoKana.bind('#user_nm', '#user_kn', { katakana: true });
	},[]);

	function handleNameInput(ev) {
		fieldData.user_nm = ev.target.value;
		fieldData.user_kn = autokana.getFurigana();
	}

    /**
     * 更新内容チェック
     * @param e
     */
	function handelOnSubmit(e) {
		fieldData.user_birth_date = fieldData.birth_date.year + '-' + fieldData.birth_date.month + '-' + fieldData.birth_date.day;
		fieldData.id = dbconn.id;
		e.preventDefault();
		setErrorClear();
		if ((fieldData.line_id != null && fieldData.line_id != "") &&
			(fieldData.email == null || fieldData.email == "")) {
			submitPost('api/line/chk-user-update', {
				...fieldData,
			});
		} else {
			submitPost('api/chk-user-update', {
				...fieldData,
			});
		}
	}

	/**
	 * 日付選択反映
	 * @param date
	 */
	function handleDate(date) {
		setManualField({
			...fieldData,
			birth_date: date,
		})
	}

    /**
     * チェック結果エラー
     */
    useEffect(() => {
        if (Object.keys(errors).length > 0) {
			window.scrollTo(0, 0);
        }
    }, [errors])

    /**
     * チェック結果正常
     */
    useEffect(() => {
        if (Object.keys(result).length > 0) {
            onSubmitDataForVerify(fieldData);
        }
    }, [result]);

	return (
		<>
			<Form className="user-update-form" onSubmit={handelOnSubmit}>
				<div className={passInfo_header_style} >
					▼バスワード情報
				</div>
				<Row className={default_header_style}>
					<Required/> メールアドレス(例) xxx@xxx.co.jp
				</Row>
				<Row className={line_style}>
					メールアドレス(例) xxx@xxx.co.jp
				</Row>
				<Row className={default_style}>
					<FormControl
						className="registration-form-input-field"
						type="text"
						value={fieldData.email}
						placeholder="メ ー ル ア ド レ ス を 入 力 し て く だ さ い"
						onChange={handleDataFormat}
						name="email"
						maxLength="255"
						readOnly="readOnly"
						{...FormInput}
					/>
				</Row>
				{errors.email && (
					<Row className={default_style}>
						<span className="error-span">{errors.email[0]}</span>
					</Row>
				)}
				<Row className={default_header_style}>
					<Required/> パスワード(半角英数字の組み合わせで8文字以上20文字以内で記載してください)
				</Row>
				<Row className={line_style}>
					パスワード(半角英数字の組み合わせで8文字以上20文字以内で記載してください)
				</Row>
				<Row className={default_style}>
					<FormControl
						className="registration-form-input-field"
						type="password"
						placeholder="パスワードを入力してください"
						value={fieldData.password}
						onChange={handleDataFormat}
						name="password"
						maxLength="20"
						{...FormInput}
					/>
				</Row>
				{errors.password && (
					<Row className={default_style}>
						<span className="error-span">{errors.password[0]}</span>
					</Row>
				)}
				<Row className={default_header_style}>
					<Required/> パスワード確認(確認のため、もう一度入力してください)
				</Row>
				<Row className={line_style}>
					パスワード確認(確認のため、もう一度入力してください)
				</Row>
				<Row className={default_style}>
					<FormControl
						className="registration-form-input-field"
						type="password"
						placeholder="上記と同じパスワードを再入力してください"
						value={fieldData.confirm_password}
						onChange={handleDataFormat}
						name="confirm_password"
						maxLength="20"
						{...FormInput}
					/>
				</Row>
				{errors.confirm_password && (
					<Row className={default_style}>
						<span className="error-span">{errors.confirm_password[0]}</span>
					</Row>
				)}
				<div className="text-header second-header disp-left-space-area">
					▼お客様情報
				</div>
				<Row className="registraion-form-row">
					<Required/> お名前(例) 山本 太郎
				</Row>
				<Row className="registraion-form-row">
					<FormControl
				    	className="registration-form-input-field"
					    type="text"
						value={fieldData.user_nm}
						onChange={handleDataFormat}
						onInput={handleNameInput}
					    name="user_nm"
						id="user_nm"
						maxLength="50"
					    {...FormInput}
				    />
				</Row>
				{errors.user_nm && (
					<Row className="registraion-form-row">
						<span className="error-span">{errors.user_nm[0]}</span>
					</Row>
				)}
				<Row className="registraion-form-row">
					<Required/> フリガナ(カタカナで入力してください)(例) ヤマモト タロウ
				</Row>
				<Row className="registraion-form-row">
					<FormControl
				    	className="registration-form-input-field"
					    type="text"
						value={fieldData.user_kn}
					    onChange={handleDataFormat}
					    name="user_kn"
						id="user_kn"
						maxLength="50"
					    {...FormInput}
				    />
				</Row>
				{errors.user_kn && (
					<Row className="registraion-form-row">
						<span className="error-span">{errors.user_kn[0]}</span>
					</Row>
				)}
				<Row className="registraion-form-row">
					<Required/>電話番号
				</Row>
				<Row className="registraion-form-row">
					<FormControl
						className={default_tel_style}
						type="text"
						value={fieldData.user_tel}
						onChange={handleDataFormat}
						name="user_tel"
						maxLength="15"
						{...FormInput}
					/>
				</Row>
				{errors.user_tel && (
					<Row className="registraion-form-row">
						<span className="error-span">{errors.user_tel[0]}</span>
					</Row>
				)}
				<Row className="registraion-form-row">
					<Required/> 性別
				</Row>
				<Row className="registraion-form-row">
					<label>
						<input
							className="user-update-detail-radio"
							type="radio"
							name="user_sex"
							value="0"
							onChange={handleDataFormat}
							checked={fieldData.user_sex === "0"}
						/>男 性
					</label>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<label>
						<input
							type="radio"
							className="user-update-detail-radio"
							name="user_sex"
							value="1"
							onChange={handleDataFormat}
							checked={fieldData.user_sex === "1"}
						/>女 性
					</label>
				</Row>
				{errors.user_sex && (
					<Row className="registraion-form-row">
						<span className="error-span">{errors.user_sex[0]}</span>
					</Row>
				)}
				<Row className="registraion-form-row">
					生年月日
				</Row>
				<Row className="registraion-form-row">
					<DatePicker date={handleDate} defaultData={fieldData.birth_date}/>
				</Row>
				{errors.user_birth_date && (
					<Row className="user-update-form-title">
						<span className="error-span">{errors.user_birth_date[0]}</span>
					</Row>
				)}
				<Row className="registraion-form-row">
					{shop.shop_nm != undefined && shop.shop_nm != ''? shop.shop_nm + 'からの'　: ''}お知らせ
				</Row>
				<Row className="registraion-form-row">
					<label>
						<input
							className="user-update-detail-radio"
							type="radio"
							value="1"
							checked={fieldData.info_mailflg === "1"}
							onChange={handleDataFormat}
							name="info_mailflg"
						/>希望する
					</label>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<label>
						<input
							className="user-update-detail-radio"
							type="radio"
							value="0"
							checked={fieldData.info_mailflg === "0"}
							onChange={handleDataFormat}
							name="info_mailflg"
						/>希望しない
					</label>
				</Row>
				{errors.info_mailflg && (
					<Row className="registraion-form-row">
						<span className="error-span">{errors.info_mailflg[0]}</span>
					</Row>
				)}
				<Row className="registraion-form-row">
					※「希望しない」を選択した場合でも、ご予約内容確認等の通知は受け取ることが出来ます。
				</Row>
				<Row className={line_style}>
					お知らせ配信先
				</Row>
				<Row className={line_style} >
					<label>
						<input
							className="user-update-detail-radio"
							type="radio"
							value="1"
							checked={fieldData.send_kbn === "1"}
							onChange={handleDataFormat}
							name="send_kbn"
						/>メール
					</label>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<label>
						<input
							className="user-update-detail-radio"
							type="radio"
							value="2"
							checked={fieldData.send_kbn === "2"}
							onChange={handleDataFormat}
							name="send_kbn"
						/>LINE
					</label>
				</Row>
				{errors.send_kbn && (
					<Row className={line_style}>
						<span className="error-span">{errors.send_kbn[0]}</span>
					</Row>
				)}

				<Row className="registration-button-container disp-left-space-area disp-right-space-area">
					<Col md={{ span: 4, offset: 8 }}>
						<Button block className="user-update-button" type="submit">
							更 新
						</Button>
					</Col>
				</Row>
			</Form>
		</>
	)
}

export default withRouter(UserUpdateForm);
