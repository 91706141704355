import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useConn from 'Utils/EndPoints/useConn';

import './Top.css';
import {APP_TOP, PRM_ID} from "../../Utils/Common/ComConst";
import Http from 'Utils/Axios';
import Auth from 'Utils/Auth';

function TopBack({ appdata }) {
	let history = useHistory();
	const [urlpram, dbconn, loadingConn] = useConn(appdata);
	const [start, setStart] = useState('0');

	/**
	 * URLパラメータ取得
	 */
	useEffect(() => {
		if (loadingConn) return;
		setStart('1');
	}, [loadingConn])

	/**
	 * TOP画面遷移
	 */
	useEffect(() => {
		if (start != '1') return;

		Http.post('api/auth/logout').then(response => {
		}).catch(error => {
			
		}).finally(() => {
			Auth.SetAccessToken();
			appdata.init();
			history.replace(APP_TOP + urlpram);
		});
	}, [start])

	return (
		<p style={{padding: '15px'}}>
			ログアウトしています。。。
		</p>
	);
}

export default TopBack;
