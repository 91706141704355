/**
 * URLパラメータ取得
 * @param urlval
 * @param search
 * @returns {string}
 */
import {PRM_ID} from "./ComConst";

export function fncgGetUrlPrams(urlval, search)
{

    let idxSearch = urlval.indexOf( search );
    if(idxSearch < 0){
        return '';
    }else{
        let strUrlPram = urlval.substring(idxSearch);
        let params = {id : ''}

        //urlパラメータをオブジェクトにまとめる
        let aryPram = strUrlPram.split('&');
        let aryTemp = aryPram[0].split('=');
        params.id = aryTemp[1];
        /*
        strUrlPram.split('&').forEach( param => {
            const temp = param.split('=')
            //pramsオブジェクトにパラメータを追加
            params = {
                ...params,
                [temp[0]]: temp[1]
            }
        })
        */
        return params;
    }
}

/**
 * パラメータ作成
 */
export function fncgSetUrlPram(urlpram){
    let strPram = '';
    if(urlpram != '' && urlpram.id != undefined){
        strPram = '?' + PRM_ID + '=' + urlpram.id;
    }
    return strPram;
}

/**
 * パラメータ取得(ID以外)
 * @param urlval
 * @param search
 * @returns {string}
 */
export function fncgGetUrlPramInfo(urlval, search) {
    let idxSearch = urlval.indexOf(search);
    if (idxSearch < 0) {
        return '';
    } else {
        let strUrlPram = urlval.substring(idxSearch);
        let params = '';
        //urlパラメータをオブジェクトにまとめる
        let aryPram = strUrlPram.split('&');
        let aryTemp = aryPram[0].split('=');
        params = aryTemp[1];
        return params;
    }
}

export function fncgGetID() {
    let pram = fncgGetUrlPrams(document.location.href, PRM_ID);
    return pram != '' ? pram.id : '';
}

/*
  Detect in-app browser by UserAgent.
  e.g. `console.log('your browser ' + detectInAppBrowser(navigator.userAgent))`
  
  @param {string} ua UserAgent　string
  @returns {string | null} [is|maybe]_[safari|chrome|facebook|instagram|line]_[ios|android|unknown]
 */
  export function detectInAppBrowser() {
    let ua = window.navigator.userAgent;
    ua = ua.toLowerCase().trim();

    // iOS Chrome
    if (ua.includes('crios')) {
        return 'is_chrome_ios';
    }

    const isIOS =
        ua.includes('iphone') || ua.includes('ipod') || ua.includes('ipad');
    const isAndroid = ua.includes('android');

    // Facebook
    if (ua.includes('fbios') || ua.includes('fb_iab')) {
        return isIOS
        ? 'is_facebook_ios'
        : isAndroid
        ? 'is_facebook_android'
        : 'is_facebook_unknown';
    }

    // Instagram
    if (ua.includes('instagram')) {
        return isIOS
        ? 'is_instagram_ios'
        : isAndroid
        ? 'is_instagram_android'
        : 'is_instagram_unknown';
    }

    // LINE
    if (ua.includes(' line/')) {
        return isIOS
        ? 'is_line_ios'
        : isAndroid
        ? 'is_line_android'
        : 'is_line_unknown';
    }

    // iOS Safari|Twitter|Slack|Discord|etc
    if (isIOS && /safari\/[0-9.]+$/.test(ua)) {
        return 'maybe_safari_ios';
    }

    // Android Chrome|Twitter|Slack|Discord|etc
    if (isAndroid && ua.includes('chrome') && /safari\/[0-9.]+$/.test(ua)) {
        return 'maybe_chrome_android';
    }

    return '';
}