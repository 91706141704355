import React, { useState } from 'react';
import Http from 'Utils/Axios';

function useFetch() {
	const [response, setResponse] = useState({});
	const [responseError, setResponseError] = useState('');
	// const [loading, setLoading] = useState(true);
	
		function submitGet(url) {
			Http.get(url)
				.then(response => {
					setResponse(response.data)
				}).catch(error => {
					setResponseError(error)
				});
		}
	
	return [response, submitGet, responseError];
}

export default useFetch;