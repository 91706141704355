import React, { useState, useEffect } from 'react';
import Http, {CancelToken} from 'Utils/Axios';
import {IsDbconnId} from './useConn';

function useShop(defData, conn) {
    const [dbconn]  = useState(conn);
    /** 全てのDB読み込みが完了した時点でfalseが設定されます。 */
    const [loading, setLoading] = useState(true);
    /** レコード情報が設定されます。 */
    const [data, setData] = useState(null);
    /** */
    const source = CancelToken.source();

    useEffect(() => {
        let mount = true;

        if (!IsDbconnId(dbconn)) return;

        let cache = null;
        if (defData && 'shop' in defData) cache = defData.shop;

        if (cache != null) {
            // キャッシュから取得
            setData(cache);
            setLoading(false);
        } else {
            // DBから読み込み
            if (mount) loadDB(true);
        }

        return () => {
            mount = false;
        }
    }, [dbconn]);

    /**
     * データをDBから読み込みします。
     */
    function loadDB(force) {
        if (!force && loading) return;

        setLoading(true);
        setData(null);
        Http.post('api/get-shop',{...dbconn}).then(response => {
            if (response.status == 200) setData(response.data);
        }).catch(error => {

        }).finally(() => {
            setLoading(false);
        });
    }

    function cancel() {
        source.cancel('Operation canceled by the user.');
    }

    return [data, setData, loading, loadDB, cancel];
}

export function IsEmailAuth(shop) {
    return shop.security_flg == '0';
}

export default useShop;