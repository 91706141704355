import axios from 'axios';
import React from 'react';
import Auth from './Auth';

import Notification from 'Component/Notification/Notification';
import 'react-notifications/lib/notifications.css';
import VersionCheck, { GetRequestVersion } from './version';

// create new instance
const Http = axios.create();
export const CancelToken = axios.CancelToken;

// set default config
Http.defaults.baseURL = process.env.REACT_APP_API_URL;
Http.defaults.headers.common.Accept = 'application/json';

Http.interceptors.request.use(request => {
	let token = Auth.GetAccessToken();
	if (token) {
		request.headers.common.Authorization = `Bearer ${token}`;
	}
	return request;
});

Http.interceptors.request.use(request => {
	request.data = {...request.data, 'ApplicationVersion': GetRequestVersion()};
	return request;
})

Http.interceptors.response.use(undefined, error => {
	if (!VersionCheck(error)) {
		return;
	//} else if (typeof error.response === 'undefined') {
	//	return Notification('error', 'A network error occured.');
	}

	switch (error.response.status) {
		case 500:
			//Notification('error', error.response.data.error);
	}

	return Promise.reject(error)
});


// Export axios as Http name
export default Http;