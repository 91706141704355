import React, {useEffect, useState} from 'react';

import { useHistory, useParams, useLocation } from 'react-router-dom';
import useConn, {IsDbconnId} from 'Utils/EndPoints/useConn';
import useUser, {IsTempUser} from 'Utils/EndPoints/useUser';
import useShop, {IsEmailAuth} from 'Utils/EndPoints/useShop';

import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import RegistrationDetailForm from './RegistrationDetailForm/RegistrationDetailForm';
import RegistrationFormVerify from './RegistrationFormVerify/RegistrationFormVerify';

export default function RegistrationDetail({ appdata }) {
	let location = useLocation();
	let history = useHistory();
	let { token } = useParams();
	const [urlpram, dbconn, loadingConn] = useConn(appdata);
	const [user, setUser, loadingUser] = useUser(appdata);
	const [shop, setShop, loadingShop] = useShop(appdata, dbconn);

	const [data, setData] = useState({});
	const [rawData, setRawData] = useState({});
	const [verify, setVerify] = useState(false);
	const [start, setStart] = useState('0');
	const [skipPW, setSkipPW] = useState(false);
	const [loader, setLoader] = useState(true);

	/**
	 * URLパラメータ取得
	 */
	useEffect(() => {
		if (loadingUser || loadingShop) return;
		//--- パスワードスルー設定
		if (user) {
			setSkipPW(true);
		}
		if (!shop) {

		}
		setStart('1');
	}, [loadingUser, loadingShop])

	useEffect(() => {
		if(start != '1' || !IsDbconnId(dbconn)) return;

		//--- メール認証判定（トークン指定ある場合は認証ありと判定する）
		if(IsEmailAuth(shop) || token){
			//-- メール認証あり
			//-- 会員登録用に送信したURLの有効期間を判定
			let aryData = {
				token : token,
				id : dbconn.id,
			}
			Http.post('api/token/email', {
				...aryData
			}).then(response => {
				if (response.data.results.IsTempUser) {
					// ログインしていない
					if (!user) {
						history.replace('/login' + urlpram, { from: location });
						return;
					}
					// ログインユーザーが登録対象ユーザーと異なる
					if (user.user_id != response.data.results.user_id) {
						setRawError();
						return;
					}
				}
				if (response.data.status == 200) {
					setRawData({
						results: {...response.data.results},
						status: response.data.status,
					});
				} else {
					setRawError();
				}
			}).catch(error => {
				setRawError();
				}).finally(() => {
					setLoader(false);
			});
		} else {
			//-- メール認証なし
			setLoader(false);
		}
	}, [start])

	function setRawError() {
		setRawData({error : 'token valid error.'});
	}

	function handleSubmitForVerify(data) {
		setData(data);
		setVerify(true);
	}

	function handleSubmitForRegistration(data) {
		setData(data);
		setVerify(false);
	}

	const Loader = () => {
		return (<div>お待ちください。。。</div>);
	};

	return (
		<>
			<Layout headerText="会員登録">
				{loader ? <Loader /> : shop != "" && shop != undefined && (
					<>
						{!verify ? (
							<RegistrationDetailForm 
								token={token}
								onSubmitDataForVerify={handleSubmitForVerify}
								data={data}
								rawData={rawData}
								shop={shop}
								user={user}
								dbconn={dbconn}
								history={history}
								skipPW={skipPW}
							/>
						) : (
							<RegistrationFormVerify
								token={token}
								history={history}
								returnRegistrationForm={handleSubmitForRegistration}
								data={data}
								shop={shop}
								dbconn={dbconn}
								urlpram={urlpram}
								skipPW={skipPW}
								isAuth={user ? true : false}
							/>	
						)}
					</>
				)}
			</Layout >
		</>
	);

}
