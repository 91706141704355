import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useConn from 'Utils/EndPoints/useConn';
import useUser from 'Utils/EndPoints/useUser';

import { Button, Col, Row } from 'react-bootstrap';

import Layout from 'layouts/Default/Default';
import './RegistrationComplete.css';
import {APP_TOP, PRM_ID} from "../../../Utils/Common/ComConst";

function RegistrationComplete({ appdata }) {
	let history = useHistory();
	const [urlpram, dbconn, loadingConn] = useConn(appdata);
	const [user, setUser, loadingUser] = useUser(appdata);

	function fncOnClickTop(){
		history.push(APP_TOP + urlpram);
	}

	return (
		<>
			<Layout headerText="会員登録">
				<div className="registration-label">
					<a href="javascript:void(0);">会員登録が完了しました。</a>
					<br/>
					会員番号 ({ user && user.user_id })
				</div>
				<Row className="justify-content-md-center disp-left-space-area disp-right-space-area">
					<Col md={6} >
						{(!loadingConn && !loadingUser) && (
							<Button className="registration-complete-button" onClick={fncOnClickTop}>
								予 約 画 面 へ
							</Button>
						)}
					</Col>	
				</Row>
			</Layout >
		</>
	)
}

export default RegistrationComplete;