import React, {useEffect, useState} from 'react';
import { Route,  Redirect, BrowserRouter as Router, Switch, useParams } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import Top from './Screens/Top/Top'
import TopBack from "./Screens/Top/TopBack";
import ReserveList from './Screens/Reserve/ReserveList/ReserveList'
import ReserveHistory from "./Screens/Reserve/ReserveHistory/ReserveHistory";
import UserUpdate from "./Screens/UserUpdate/UserUpdate";
import Login from './Screens/Login/Login'
import SignUp from './Screens/SignUp/SignUp'
import NotFound from './Screens/NotFound/NotFound'
import DashBoard from './Screens/DashBoard/DashBoard'
import PasswordReset from './Screens/Password/PasswordReset/PasswordReset'
import PasswordResetComplete from './Screens/Password/PasswordResetComplete/PasswordResetComplete'
import RegistrationDetail from './Screens/Registration/RegistrationDetail/RegistrationDetail'
import RegistrationComplete from './Screens/Registration/RegistrationComplete/RegistrationComplete'
import ForgotPassword from './Screens/Password/ForgotPassword/ForgotPassword'
import LineLogin from './Screens/Line/Login/LineLogin'
import LineRegistrationDetail from './Screens/Line/Registration/RegistrationDetail'

import useConn, { GetDbconn, IsDbconnId } from './Utils/EndPoints/useConn';
import useUser, {IsTempUser} from './Utils/EndPoints/useUser';
import Auth, { GetOlderSettingID, GetOlderSettingPW, IsOlderSettings } from './Utils/Auth';
import Http from './Utils/Axios';
import useShop from './Utils/EndPoints/useShop';
import { APP_TOP } from './Utils/Common/ComConst';

const history = createBrowserHistory();
const appdata = {
	urlpram: null,
	dbconn: null,
	user: null,
	shop: null,
	setDatas: (name, data) => {
		if (name in appdata) {
			appdata[name] = data;
		}
	},
	init: () => {
		appdata.urlpram = null;
		appdata.dbconn = null;
		appdata.user = null;
		appdata.shop = null;
	}
};

function App () {
	const [loader, setLoader] = useState(true);

	useEffect(() => {
		let conn = GetDbconn();

		if (!IsOlderSettings() || !IsDbconnId(conn)) {
			setLoader(false);
			return;
		}

		// 認証を差し替え
		let data  = {
			password : GetOlderSettingPW(),
			username : GetOlderSettingID(),
			id : conn.id
		};
		Http.post('/api/login', {
			...data,
		}).then(response => {
			if(response.status == '200'){
				Auth.SetAccessToken(response.data.token);
			} else {
				Auth.SetAccessToken();
			}
		}).catch(error => {
			Auth.SetAccessToken();
		}).finally(() => {
			setLoader(false);
		})
	}, []);

	return (loader ? <LoaderDOM /> :
		<Router history={history}>
			<Switch>
				{/** top */}
				<ConnRoute exact path="/" component={Top} />
				<TokenRoute exact path="/linetop/:token" component={Top} />
				<TokenRoute exact path="/line-reserve-list/:token" component={Top} />
				{/** login/out */}
				<ConnRoute exact path="/login" component={Login} />
				<ConnRoute exact path="/login/reset" component={Login} />
				<ConnRoute exact path="/linelogin/:token" component={LineLogin} />
				<Route exact path="/back" render={(props) => <TopBack {...props} appdata={appdata} />} />
				{/** reserve */}
				<PrivateRoute exact path="/reserve-list" component={ReserveList} />
				<PrivateRoute exact path="/reserve-history" component={ReserveHistory} />
				{/** user regist/update */}
				<GuestOrTempRoute exact path="/sign-up/:token" component={SignUp} />
				<GuestOrTempRoute exact path="/registration/detail/:token" component={RegistrationDetail} />
				<GuestOrTempRoute exact path="/registration/detail" component={RegistrationDetail} />
				<GuestOrTempRoute exact path="/line-registration/detail/:token" component={LineRegistrationDetail} />
				<PrivateRoute exact path="/registration/complete" component={RegistrationComplete} />
				<PrivateRoute exact path="/user-update" component={UserUpdate} />
				{/** pw */}
				<GuestRoute exact path="/password/forgot-password" component={ForgotPassword} />
				<GuestRoute exact path="/password/reset/complete" component={PasswordResetComplete} />		
				<GuestRoute exact path="/password/reset/:token" component={PasswordReset} />
				{/** other */}
				<Route exact path="/not-found" component={NotFound} />
				<Route component={NotFound} />
			</Switch>
		</Router>
	);
}

const LoaderDOM = () => {
	return (
		<></>
	);
};

function renderNotFound(props, urlpram) {
	history.replace({pathname: '/not-found' + urlpram, state: { from: props.location }});
	history.go(0);
	return <></>;
}
function renderLogin(props, urlpram) {
	history.replace({pathname: '/login' + urlpram, state: { from: props.location }});
	history.go(0);
	return <></>;
}
function renderTop(props, urlpram) {
	history.replace({pathname: APP_TOP + urlpram});
	history.go(0);
	return <></>;
}

function ConnRoute(props) {
	/** 未ログイン/ログイン共用の場合 */
	const { component: Component, ...rest } = props;
	const [ urlpram, dbconn, loadingConn ] = useConn(appdata);
	const [ shop, setShop, loadingShop ] = useShop(appdata ,dbconn);
	const [ user, setUser, loadingUser ] = useUser(appdata);

	appdata.urlpram = urlpram;
	appdata.dbconn = dbconn;
	appdata.user = user;
	appdata.shop = shop;

	function renderComponent() {
		return !dbconn.dbname ? (
			renderNotFound(props, urlpram)
		) : (
			<Component {...props} appdata={appdata} />
		);
	}

	return (
		<Route
		{...rest}
		render={() => 
			(loadingConn || loadingShop || loadingUser) ? (
				<LoaderDOM />
			) : (
				renderComponent()
			)
		}
		/>
	);
}

function GuestRoute(props) {
	/** ログインユーザを除外したい場合 */
	const { component: Component, ...rest } = props;
	const [ urlpram, dbconn, loadingConn ] = useConn(appdata);
	const [ shop, setShop, loadingShop ] = useShop(appdata ,dbconn);
	const [ user, setUser, loadingUser ] = useUser(appdata);

	appdata.urlpram = urlpram;
	appdata.dbconn = dbconn;
	appdata.user = user;
	appdata.shop = shop;

	function renderComponent() {
		return !dbconn.dbname ? (
			renderNotFound(props, urlpram)
		) : user ? (
			renderTop(props, urlpram)
		) : (
			<Component {...props} appdata={appdata} />
		);
	}

	return (
		<Route
		{...rest}
		render={() => 
			(loadingConn || loadingShop || loadingUser) ? (
				<LoaderDOM />
			) : (
				renderComponent()
			)
		}
		/>
	);
}

function GuestOrTempRoute(props) {
	/** ログインユーザ（仮会員を除く）を除外したい場合 */
	const { component: Component, ...rest } = props;
	const [ urlpram, dbconn, loadingConn ] = useConn(appdata);
	const [ shop, setShop, loadingShop ] = useShop(appdata ,dbconn);
	const [ user, setUser, loadingUser ] = useUser(appdata);

	appdata.urlpram = urlpram;
	appdata.dbconn = dbconn;
	appdata.user = user;
	appdata.shop = shop;

	function renderComponent() {
		return !dbconn.dbname ? (
			renderNotFound(props, urlpram)
		) : (user && !IsTempUser(user)) ? (
			renderTop(props, urlpram)
		) : (
			<Component {...props} appdata={appdata} />
		);
	}

	return (
		<Route
		{...rest}
		render={() => 
			(loadingConn || loadingShop || loadingUser) ? (
				<LoaderDOM />
			) : (
				renderComponent()
			)
		}
		/>
	);
}

function TokenRoute(props) {
	/** トークンの整合性チェックしたいですが各コンポーネント内で処理しているのでここは未処理とします。 */
	return ConnRoute(props);
}

function PrivateRoute(props) {
	/** 未ログインユーザー（仮会員を含めて）を除外したい場合 */
	const { component: Component, ...rest } = props;
	const [ urlpram, dbconn, loadingConn ] = useConn(appdata);
	const [ shop, setShop, loadingShop ] = useShop(appdata, dbconn);
	const [ user, setUser, loadingUser ] = useUser(appdata);

	appdata.urlpram = urlpram;
	appdata.dbconn = dbconn;
	appdata.user = user;
	appdata.shop = shop;

	function renderComponent() {
		return !dbconn.dbname ? (
			renderNotFound(props, urlpram)
		) : (
			!user || IsTempUser(user) ? (
				renderLogin(props, urlpram)
			) : (
				<Component {...props} appdata={appdata} />
			)
		);
	}

	return (
		<Route
		{...rest}
		render={() => 
			(loadingConn || loadingUser || loadingShop) ? (
				<LoaderDOM />
			) : (
				renderComponent()
			)
		}
		/>
	);
}

export default App;
