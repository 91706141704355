import React from 'react';

import '../UserUpdate.css';
import { Button, Col, Row } from 'react-bootstrap';
import {fncgSetUrlPram} from "../../../Utils/Common/ComCom";
import {APP_TOP} from "../../../Utils/Common/ComConst";

function UserUpdateComplete({ data, history, dbconn }) {

    function handleTopPage() {
		let strPram = fncgSetUrlPram(dbconn);
        history.push(APP_TOP + strPram);
    }

	return (
		<>
			<div className="user-update-label ">
				<a href="javascript:void(0);">会員変更が完了しました。</a>
                <br/>
				 会員番号 ({ data.user_id })
			</div>
			<Row className="justify-content-md-center disp-left-space-area disp-right-space-area">
				<Col md={6} >
					<Button className="user-update-complete-button" onClick={handleTopPage}>
						予 約 画 面 へ
					</Button>
				</Col>
			</Row>
		</>
	)
}

export default UserUpdateComplete;