import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useConn from 'Utils/EndPoints/useConn';
import useUser from 'Utils/EndPoints/useUser';
import useShop from 'Utils/EndPoints/useShop';

import './UserUpdate.css';
import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import UserUpdateForm from "./components/UserUpdateForm";
import UserUpdateVerify from "./UserUpdateVerify/UserUpdateVerify";
import UserUpdateComplete from "./UserUpdateComplete/UserUpdateComplete";

function UserUpdate({ appdata }) {
	let history = useHistory();
	const [urlpram, dbconn, loadingConn] = useConn(appdata);
	const [user, setUser, loadingUser, forceLoad] = useUser(appdata);
	const [shop, setShop, loadingShop] = useShop(appdata, dbconn);

	const [data, setData] = useState({});
	const [disp, setDisp] = useState('0');
	const [start, setStart] = useState('0');
	const [init, setInit] = useState(true);


	useEffect(() => {
		if (loadingUser || !init) return;
		setInit(false);
		forceLoad();
	}, [loadingUser, init])

	/**
	 * URLパラメータ取得
	 */
	useEffect(() => {
		if (loadingConn || loadingUser || loadingShop || init) return;
		setStart('1');
	}, [loadingConn, loadingUser, loadingShop, init])

    /**
     * 会員更新確認画面表示
     * @param data
     */
	const handleSubmitForVerify = data => {
		data.user_birthday_year = data.birth_date.year;
		data.user_birthday_month = data.birth_date.month;
		data.user_birthday_day = data.birth_date.day;
		setData(data);
		setDisp('1');
		window.scrollTo(0, 0);
	}

    /**
     * 会員更新画面再表示
     * @param errors
     */
    const handleReturnUserUpdate = data => {
		setUser(data);
        setDisp('0');
		window.scrollTo(0, 0);
    }

    /**
     * 会員更新完了画面表示
     * @param data
     */
    const handleSubmitDataUpdate = data => {
		setUser(data);
		setDisp('2');
		window.scrollTo(0, 0);
    }

	return (
		<>
			{(disp == '0' && start != '0') && (
				<Layout headerText="会 員 変 更" appdata={appdata}>
					<UserUpdateForm
						data={user}
						shop={shop}
						dbconn={dbconn}
						onSubmitDataForVerify={handleSubmitForVerify}
					/>
				</Layout >
			)}
				
			{(disp == '1' && start != '0') && (
				<Layout headerText="会 員 変 更 確 認" appdata={appdata}>
					<UserUpdateVerify
						data={data}
						shop={shop}
						dbconn={dbconn}
						history={history}
						onSubmitDataUpdate={handleSubmitDataUpdate}
						returnUserUpdateForm ={handleReturnUserUpdate}
					/>
				</Layout>
			)}

			{(disp == '2' && start != '0') && (
				<Layout headerText="会 員 変 更" appdata={appdata}>
					<UserUpdateComplete
						data={user}
						dbconn={dbconn}
						history={history}
					/>
				</Layout>
			)}
		</>
	);

}

export default UserUpdate;