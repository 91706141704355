import React, { useState, useEffect } from 'react';

import './DatePicker.css';
import { Col, Row, Form } from 'react-bootstrap';

function DatePicker(props){
	const [date, setDate] = useState(props.defaultData);
	let days = [];
	let years = [];
	const months = [];

	var dtNow = new Date();
	years.push('');
	for (var i = 1900; i <= dtNow.getFullYear(); i++) {
		years.push(i);
	}
	months.push('');
	for (var i = 1; i <= 12; i++) {
		months.push(fncSetPadding(i));
	}
	days.push('');
 	for (var i = 1; i <= 31; i++) {
		days.push(fncSetPadding(i));
	}

	function fncSetPadding(int_num, size = 2) {

		var str_formatted_number = int_num + "";

		while (str_formatted_number.length < size) str_formatted_number = "0" + str_formatted_number;

	    return str_formatted_number;
	}

	useEffect(() => {
		props.date(date)
	}, [date])

	function handleChange(e) {
		setDate({
			...date,
			[e.target.name]: e.target.value
		});
	}

	return (
		<>
			<Form.Control as="select" className="datepicker-year" name='year' value={date.year} onChange={ handleChange } >
				{years.map((value, key) => (
					<option value={value} key={key}>{value}</option>
				))}
			</Form.Control>
			<label className="label-datepicker">年</label>
			<Form.Control as="select" className="datepicker-month" name='month' value={date.month} onChange={ handleChange } >
				{months.map((value, key) => (
					<option value={value} key={key}>{value}</option>
				))}
			</Form.Control>
			<label className="label-datepicker">月 </label>
			<Form.Control as="select" className="datepicker-day" name='day' value={date.day} onChange={ handleChange } >
				{days.map((value, key) => (
					<option value={value} key={key}>{value}</option>
				))}
			</Form.Control>
			<label className="label-datepicker">日</label>

 		</>
	)
}

export default DatePicker;