import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useConn, {IsDbconnId} from 'Utils/EndPoints/useConn';

import './PasswordReset.css'
import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import usePost from 'Utils/EndPoints/usePost';
import { Button, Modal  } from 'react-bootstrap';
import CustomModal from 'Utils/Modal/CustomModal';
import PasswordResetForm from './components/PasswordResetForm';
import {APP_TOP, PRM_ID} from "../../../Utils/Common/ComConst";
import Auth from 'Utils/Auth';
import useShop, { IsEmailAuth } from '../../../Utils/EndPoints/useShop';

function PasswordReset({ appdata }) {
	let history = useHistory();
	let { token } = useParams();
	const [ urlpram, dbconn, loadingConn ] = useConn(appdata);
	const [ shop, setShop, loadingShop ] = useShop(appdata, dbconn);

	const START_INIT = '0';
	const START_PASS_SET = '1';
	const START_LOGIN_CHK = '2';
	const START_COMPLETE = '3';

	const [result, submitPost, errors] = usePost();
	const [show, setShow] = useState(false);
	const [start, setStart] = useState(START_INIT);
	const [modalType, setModalType] = useState(0);

	const [registerToken, setRegisterToken] = useState('');
	const [isTemp, setIsTemp] = useState(false);

	const ModalMsg = () => {
		return modalType == 0 ?
			"パスワードは正常にリセットされました。":
			"パスワード再設定に失敗しました。";
	};

	useEffect(() => {
		if (loadingConn || loadingShop) return;
		setStart(START_PASS_SET);
	}, [loadingConn || loadingShop])

	/**
	 * 登録結果処理
	 */
	useEffect(() => {
		if (start != START_PASS_SET) return;
		if (Object.keys(result).length > 0) {
			setRegisterToken(result.token);
			if (result.isTempUser) setIsTemp(true);
			setShow(true);
		}
	}, [result]);

	useEffect(() =>{
		if (errors === undefined) {
			setModalType(1);
		}
	}, [errors])

	/**
	 * 登録処理実行
	 * @param data
	 * @constructor
	 */
	const OnSubmit = data => {
		setModalType(0);
		submitPost('api/password-reset', data);	
	}

	/**
	 * モーダル画面クローズ
	 */
	function handleClose() {
		if (!show) return;
		setShow(false);
		if (modalType == 0) {
			setStart(START_COMPLETE);
			if (isTemp) {
				history.push('/registration/detail/' + registerToken + urlpram);
			} else {
				history.push('/password/reset/complete' + urlpram);
			}
		} else {
			history.push(APP_TOP + urlpram);
		}
	}

	return (
		<div>
			{(start == START_PASS_SET) && (
				<Layout headerText="パスワード再設定" appdata={appdata}>
					<PasswordResetForm
						token={token}
						onSubmitData={OnSubmit}
						errors={errors}
						history={history}
						dbconn={dbconn}
					/>
				</Layout>
			)}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>				
						メッセージ						
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ModalMsg />
				</Modal.Body>
				<Modal.Footer>
				<Button variant="primary" onClick={handleClose}>
					OK
				</Button>
				</Modal.Footer>
			</Modal>
        </div>
    )
}	

export default PasswordReset;
