import React, { useState, useEffect } from 'react';
import Http from 'Utils/Axios';

function usePost() {
	const [response, setResponse] = useState({});
	const [errors, setErrors] = useState({});
	const [isLoading, setLoading] = useState(false);
	const [done, setDone] = useState(false);

	function submitPost(url, data) {
		setLoading(true);

		Http.post(url, data)
			.then(response => {
				setLoading(false)
				setResponse(response.data)
			}).catch(error => {
				setLoading(false)
				if (error.response) {
					setErrors(error.response.data.errors)
				}
			}).finally(() => {
				setDone(true);
			})
	}

	function setErrorClear(){
		setErrors({});
	}

	return [response, submitPost, errors, setErrorClear, isLoading, done];
}

export default usePost;