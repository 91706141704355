import React, { useState } from 'react'

import '../../../Utils/Common/com-style.css';
import { Link } from 'react-router-dom';
import Required from 'Component/Required/Required';
import { FormInput } from 'Utils/FormHandlers/HandleFormProperties'
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData'
import { Button, Col, Row, FormControl } from 'react-bootstrap';
import {APP_TOP} from "../../../Utils/Common/ComConst";
import {fncgSetUrlPram} from "../../../Utils/Common/ComCom";

const defaultState = {
	value : "",
	id : "",
}

function SignUpForm({ history, onSubmitData, errors, isLoading, dbconn }) {
	const [data, handleDataFormat] = useHandleFormData(defaultState)
	if(dbconn != '' && dbconn.id != undefined){
		data.id = dbconn.id;
	}

	function handelOnSubmit(e) {
		e.preventDefault();
		onSubmitData(data);
	}

	/**
	 * 戻る
	 */
	function fncOnClickBack(){
		let urlpram = fncgSetUrlPram(dbconn);
		history.push(APP_TOP + urlpram);
	}

	return (
		<div>	

			<form onSubmit={handelOnSubmit}>
				<Row className={"disp-left-space-area disp-right-space-area"}>
					<Required/> メールアドレス(例) xxx@xxx.co.jp
				</Row>
				<Row className={"disp-left-space-area disp-right-space-area"}>
					<FormControl
					    type="text"
					    value={data.value}
					    placeholder="メールアドレスを入力してください"
					    onChange={handleDataFormat}
					    name="value"
						maxLength="255"
						{...FormInput}
				    />	
				    { errors && errors.value && (
				    	<span className="error-span">{errors.value[0]}</span>
				   	)}
			    </Row>
		    	<Row className="justify-content-md-center">
					<Col className="pc" md={4}>
						<Button className="btn-back" onClick={fncOnClickBack}>
							戻 る
						</Button>
					</Col>	
					<Col className="pc" md={4}>
						<Button 
							className="sign-up-button" 
							type="submit"
							variant="secondary"
							disabled={isLoading? 'disabled' : ''}
						>
							{isLoading ? 'ローディング中...' : '送 信'}
						</Button>
					</Col>
					<Col className="sp" xs={4}>
						<Button className="btn-back" onClick={fncOnClickBack}>
							戻 る
						</Button>
					</Col>
					<Col className="sp" xs={{span: 4, offset: 4}}>
						<Button
							className="sign-up-button"
							type="submit"
							variant="secondary"
							disabled={isLoading? 'disabled' : ''}
						>
							{isLoading ? 'ローディング中...' : '送 信'}
						</Button>
					</Col>
				</Row>
			</form>
		</div>
	)
}

export default SignUpForm;
