import React from 'react';

import Layout from 'layouts/Default/Default';

function DashBoard() {

	return 	(
		<>
			<Layout headerText="DashBoard">	
				<p className="text-header">
					Welcome to DashBoard
				</p> 				
			</Layout >
		</>
	);
}

export default DashBoard;
