import React, {useEffect, useState, useRef} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useConn, { IsDbconnId } from 'Utils/EndPoints/useConn';
import useUser, { IsTempUser } from 'Utils/EndPoints/useUser';
import useShop from 'Utils/EndPoints/useShop';

import './Top.css';
import Auth from 'Utils/Auth';
import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import Login from '../Login/Login';
import TopForm from './components/TopForm';
import ReserveFormVerify from "./ReserveFormVerify/ReserveFormVerify";
import ReserveComplete from "./ReserveComplete/ReserveComplete";
import { Button } from 'react-bootstrap';
import dompurify from 'dompurify';
import { APP_TOP } from '../../Utils/Common/ComConst';

function Top({appdata}) {
	let location = useLocation();
	let history = useHistory();
	let { token } = useParams();

	const [ urlpram, dbconn, loadingConn ] = useConn(appdata);	
	const [user, setUser, loadingUser, reloadUser] = useUser(appdata);
	const [shop, setShop, loadingShop] = useShop(appdata, dbconn);

	const [data, setData] = useState({});
	const [errors, setErrors] = useState({});
	const [verify, setVerify] = useState('');
	const [delay, setDelay] = useState(false);
	// 店舗注釈
	const [clamped, setClamped] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const handleClick = () => setClamped(!clamped);
	const refClamp = useRef(null);
	const CrampMinHeightPx = 124;
	// 店舗注釈のXSS対策
	const sanitizer = dompurify.sanitize;

	const VERIFY_TOP = '0';
	const VERIFY_LOGIN = '1';
	const VERIFY_RESERVE_VERIFY = '2';
	const VERIFY_RESERVE_COMPLETE = '3';

	const IsLine = location.pathname.indexOf('/line') != -1;
	const IsLineTopURL = location.pathname.indexOf('/linetop') != -1;

	useEffect(() => {
		setTimeout(() => {
			setDelay(true);
		}, 100);
	}, [])

	/**
	 * URLパラメータ取得
	 */
	useEffect(() => {
		if (loadingConn) return;
		if (!IsDbconnId(dbconn)) {
			history.push('/not-found' + urlpram);
		} else if(IsLine) {
			Auth.SetAccessToken();
			//--- トークンチェック
			let paramData = {
				token : token,
				id : dbconn.id,
			}
			Http.post('api/line/token-login', {
				...paramData
			}).then(response => {
				// トークン保存
				Auth.SetAccessToken(response.data.token);
				// 
				if (response.data.isTempUser) {
					history.push('/linelogin/' + token + urlpram);
				} else {
					Http.post('api/sign-up/token-verification', {
						...paramData
					});
					if (IsLineTopURL) {
						history.replace(APP_TOP + urlpram);
					} else {
						// LINE連携、予約一覧遷移
						history.replace('/reserve-list' + urlpram);
					}	
				}
			}).catch(error => {
				history.push(APP_TOP + urlpram);
			}).finally(() => {
			});
		} else {
			setVerify(VERIFY_TOP);
		}
	}, [loadingConn])

	/**
	 * 店舗情報
	 */
	useEffect(() => {
		if (verify !== VERIFY_TOP || loadingShop) return;
		// 店舗注釈の内容を省略させるかを判定します。
		// TODO: ブラウザのリサイズには対応していないので必要な場合修正必須です。
		let clamp = refClamp.current;
		if (clamp && clamp.clientHeight > CrampMinHeightPx ) {
			setShowButton(true);
			setClamped(true);
		}
	}, [verify, loadingShop])

	/**
	 * 会員情報
	 */
	useEffect(() => {
		if (loadingUser) return;
	}, [loadingUser]);

	/**
	 * 予約登録（プレ登録）を行います。
	 * @param {object} data 送信対象のデータを設定します。
	 * @param {boolean} authed 認証済みか設定します。
	 */
	const SetPreReserve = (data, authed) => {
		Http.post(authed ? '/api/auth/set-reserve' : '/api/set-reserve', {
			...data,
		}).then(response => {
			let strMoveFlg = VERIFY_LOGIN;
			if (response.status == 200) {
				setData(response.data);
				strMoveFlg = response.data.move_flg;
			}
			//--- ログイン or 予約確認 or TOP
			setVerify(strMoveFlg ?? VERIFY_TOP);
			window.scrollTo(0, 0);
		}).catch(error => {
			if (error.response.status == 401) {
				history.push('/login' + urlpram);
			}
		}).finally(() => {
		})
	};

	/**
	 * 予約登録
	 * @param data
	 */
	const onSubmit = data => {
		SetPreReserve(data, (user ? true : false));
	}

	/**
	 * 予約登録（ログイン成功後）
	 * @param data
	 */
	const onSubmitAfterLogin = data => {
		reloadUser();
		SetPreReserve(data, true);
	}

	/**
	 * 予約登録（本予約）
	 * @param data
	 */
	const onSubmitVerify = data => {
		Http.post('/api/auth/set-reserve-verify', {
			...data,
		}).then(response => {
			setData(response.data);
			//--- 予約受付
			setVerify(VERIFY_RESERVE_COMPLETE);
			window.scrollTo(0, 0);
		}).catch(error => {
		}).finally(() => {
		})
	}

	/**
	 * 予約登録確認画面の戻るボタンイベント
	 */
	function handleSubmitBackTop() {
		window.scrollTo(0, 0);
		//--- TOP画面
		setVerify(VERIFY_TOP);
	}

	return (
		<>
			{((verify == '' || !shop) && delay) && <p style={{padding:'15px'}}>お待ちください。。。</p>}

			{(verify == VERIFY_TOP && shop) && (
				<Layout headerText="予約条件選択" appdata={appdata}>
					{(shop.shop_note != null) && (
						<div
							id="clampBase"
							className={"disp-left-space-area disp-right-space-area clamp-" + (clamped && showButton ? "close" : "open")}
							dangerouslySetInnerHTML={{__html: sanitizer(shop.shop_note.replace(/(\r\n|\r|\n)/g, '<br>'))}}
							ref={refClamp}
						/>
					)}
					{(shop.shop_note != null && showButton) && (
						<Button
							className="clamp-btn btn-next"
							onClick={handleClick}
						>
							<span className={"icon " + (clamped ? "dn-arrow" : "up-arrow")} />
							{clamped ? "続きを読む" : "折りたたむ"}
						</Button>
					)}
					<hr />
					<TopForm
						onSubmitData={onSubmit}
						errors={errors}
						dbconn={dbconn}
					/>
				</Layout >
			)}

			{(verify == VERIFY_LOGIN && shop) && (
				<Login
					history={history}
					info={data}
					submitReserve={onSubmitAfterLogin}
					submitTop={handleSubmitBackTop}
					dbconn={dbconn}
				/>
			)}

			{(verify == VERIFY_RESERVE_VERIFY && shop) && (
				<Layout headerText="予約登録確認" appdata={appdata}>
					<ReserveFormVerify
						history={history}
						data={data}
						returnReserveForm={handleSubmitBackTop}
						onSubmitVerify={onSubmitVerify}
						errors={errors}
						dbconn={dbconn}
					/>
				</Layout>
			)}

			{verify == VERIFY_RESERVE_COMPLETE && (
				<ReserveComplete
					history={history}
					data={data}
					returnTopForm={handleSubmitBackTop}
					dbconn={dbconn}
				/>
			)}
		</>
	);
}

export default Top;
