import React, { useState, useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import useConn, {IsDbconnId} from 'Utils/EndPoints/useConn';
import useUser from 'Utils/EndPoints/useUser';
import usePost from 'Utils/EndPoints/usePost';

import Http from 'Utils/Axios';

import './SignUp.css';
import { Button, Modal  } from 'react-bootstrap';
import Layout from 'layouts/Default/Default';
import SignUpForm from './components/SignUpForm';
import {APP_TOP, PRM_ID, PRM_MODE} from "../../Utils/Common/ComConst";
import { IsTempUser } from '../../Utils/EndPoints/useUser';
import useShop, { IsEmailAuth } from '../../Utils/EndPoints/useShop';

function SignUp({ appdata }) {
	let history = useHistory();
	let { token } = useParams();
	const [ urlpram, dbconn, loadingConn ] = useConn(appdata);
	const [user, setUser, loadingUser] = useUser(appdata);
	const [shop, setShop, loadingShop] = useShop(appdata, dbconn);
	const [result, submitPost, errors, setErrorClear, isLoading] = usePost();

	const [start, setStart] = useState('0');
	/** For Modal */
	const [show, setShow] = useState(false);
	const [modalType, setModalType] = useState(0);

	const ModalMsg = () => {
		return modalType === 0 ? "会員登録用URLを送信いたしました。" : "メール送信に失敗しました。お手数ですが再操作をお願いします。" ;
	};

	const TokenVerification = (_token) => {
		if (token) {
			Http.post('api/sign-up/token-verification', {
				id: dbconn.id,
				token: _token
			});
		}
	};

	useEffect(() => {
		if (loadingConn || loadingShop) return;
		if (!IsEmailAuth(shop)) {
			history.push(APP_TOP + urlpram);
			return;
		}
		setStart('1');
	}, [loadingConn, loadingShop]);

	/**
	 * メール送信結果処理
	 */
	 useEffect(() => {
		if (Object.keys(result).length > 0) {
			TokenVerification(token);
			setShow(true)
		}
	}, [result]);
	
	useEffect(() => {
		if (errors === undefined) {
			setModalType(1);
			setShow(true)
		}
	}, [errors]);

	/**
	 * メール送信
	 * @param data
	 * @constructor
	 */
	const OnSubmit = data => {
		setModalType(0);
		data['user_id'] = user ? user.user_id : '';
		data['token'] = token;
		submitPost('api/register-email', data);
	}

	/**
	 * モーダル画面クローズ
	 */
	function handleClose() {
		setShow(false);
		history.push(APP_TOP + urlpram);
	}

	return (
		<div>
			<Layout headerText="会員登録">
				<div className="text-header disp-left-space-area">
					メールアドレス登録
				</div> 

				<div className="text-details disp-left-space-area">
					<label>					
						お客様のメールアドレスを入力して送信ボタンを押して下さい。
					</label>
					<br />
			
					<label>
						システムから確認メールが届きますのでURLをクリックして下さい。
					</label>
					<br />
			
					<label>
						その後会員登録ができます。
					</label>
				</div>

				<SignUpForm
					history={history}
					onSubmitData={OnSubmit}
					errors={errors}
					isLoading={isLoading || loadingUser}
					dbconn={dbconn}
				/>
			</Layout >	
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>				
						メッセージ						
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ModalMsg()}
				</Modal.Body>
				<Modal.Footer>
				<Button variant="primary" onClick={handleClose}>
					OK
				</Button>
				</Modal.Footer>
			</Modal>
		</div>

	)
}	

export default SignUp;
