import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useConn from 'Utils/EndPoints/useConn';
import useUser from 'Utils/EndPoints/useUser';

import './ReserveHistory.css';
import Layout from 'layouts/Default/Default';
import ReserveHistoryForm from "./components/ReserveHistoryForm";

function ReserveHistory({ appdata }) {
	let history = useHistory();
	const [urlpram, dbconn] = useConn(appdata);
	const [user, setUser, loadingUser] = useUser(appdata);

	const [start, setStart] = useState('0');

	/**
	 * URLパラメータ取得
	 */
	useEffect(() => {
		setStart('1');
	}, [])

	return (
		<>
            {(user != "" && user != undefined)&& (
				<Layout headerText={user.user_nm == undefined? '' : user.user_nm + '様　マイページ　予約履歴'} appdata={appdata}>
					<hr />
					<ReserveHistoryForm
						user={user}
						dbconn={dbconn}
					/>
					<hr />
				</Layout >
            )}
		</>
	);
}

export default ReserveHistory;
