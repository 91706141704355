import React, { useEffect, useState	 } from 'react';

import '../UserUpdate.css';
import '../../../Utils/Common/com-style.css';
import Http from '../../../Utils/Axios';
import useHandleFormData from '../../../Utils/FormHandlers/useHandleFormData';
import CustomModal from '../../../Utils/Modal/CustomModal';
import { withRouter } from 'react-router-dom'
import { Button, Col, Row, Form } from 'react-bootstrap';
import {MSG_NG_USER_ADD} from "../../../Utils/Common/ComConst";

function UserUpdateVerify({ data, shop, history, dbconn, onSubmitDataUpdate, returnUserUpdateForm}) {
	const formattedBirthDate = data.user_birthday_year + '年' + data.user_birthday_month + '月' + data.user_birthday_day + '日';
    const [fieldData] = useHandleFormData({ ...data});
	const [loading, setLoading] = useState('0');
	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	let defalut_style = "user-update-verify-form-row";
	let defalut_header_style = "text-header first-header disp-left-space-area";
	let line_style = "display-none";
	if (data.line_id != null && data.line_id != ""){
		if (data.email == null || data.email == "") {
			defalut_style = "display-none";
			defalut_header_style = "display-none";
		} else {
			line_style = "user-update-verify-form-row";
		}
	}

	/**
     * 更新ボタン
     */
    function fncOnClickUpdate(e){
        data.id = dbconn.id;
		setLoading('1');
        Http.post('/api/auth/set-user-update', {
            ...fieldData,
        }).then(response => {
            const strStatus = response.data.status;
            if(strStatus == '200'){
                onSubmitDataUpdate(fieldData);
				fieldData.password !== 'ABCD9999' && localStorage.setItem('test', fieldData.password)
            }else{
				setLoading('2');
				setModalMsg(MSG_NG_USER_ADD);
				setShow(true);
			}
        }).catch(error => {
            //if (error.response) {
            //    setErrors(error.response.data)
            //}
			setLoading('2');
			setModalMsg(MSG_NG_USER_ADD);
			setShow(true);
        }).finally(() => {})
    }

    /**
     * 戻るボタン
     */
    function fncOnClickBack(){
        returnUserUpdateForm(fieldData);
    }

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		setShow(false);
	}

	return (
		<>		
			<Form className="user-update-form-verify" >
				<div className={defalut_header_style}>
					▼パスワード情報
				</div>
				<Row className={defalut_style}>
					<Row className="user-update-verify-value">
						メールアドレス
					</Row>
					<Row className="user-update-verify-value">
						{data.email}
					</Row>
				</Row>
				<Row className={defalut_style}>
					<Row className="user-update-verify-value">
						パスワード
					</Row>
					<Row className="user-update-verify-value">
						{'********'}
					</Row>
				</Row>
				<div className="text-header second-header disp-left-space-area">
					▼お客様情報
				</div>
				<Row className={"user-update-verify-form-row"}>
					<Row className="user-update-verify-value">
						お名前
					</Row>
					<Row className="user-update-verify-value">
						{data.user_nm}
					</Row>
				</Row>
				<Row className={"user-update-verify-form-row"}>
					<Row className="user-update-verify-value">
						フリガナ
					</Row>
					<Row className="user-update-verify-value">
						{data.user_kn}
					</Row>
				</Row>
				<Row className={"user-update-verify-form-row"}>
					<Row className="user-update-verify-value">
						電話番号
					</Row>
					<Row className="user-update-verify-value">
						{data.user_tel}
					</Row>
				</Row>
				<Row className={"user-update-verify-form-row"}>
					<Row className="user-update-verify-value">
						性別
					</Row>
					<Row className="user-update-verify-value">
						{data.user_sex == '0'? '男性' : '女性'}
					</Row>
				</Row>
				<Row className={"user-update-verify-form-row"}>
					<Row className="user-update-verify-value">
						生年月日
					</Row>
					<Row className="user-update-verify-value">
						{formattedBirthDate}
					</Row>
				</Row>
				<Row className={"user-update-verify-form-row"}>
					<Row className="user-update-verify-value">
						{shop.shop_nm != undefined && shop.shop_nm != ''? shop.shop_nm + 'からの'　: ''}お知らせ
					</Row>
					<Row className="user-update-verify-value">
						{data.info_mailflg == '0'? '希望しない' : '希望する'}
					</Row>
				</Row>
				<Row className={line_style}>
					<Row className="user-update-verify-value">
						お知らせ配信先
					</Row>
					<Row className="user-update-verify-value">
						{data.send_kbn == '1'? 'メール' : 'LINE'}
					</Row>
				</Row>

				<Row className="user-update-verify-form-row">
					<Col className="pc" md={{ span: 3 }}>
						<Button
							block
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
					<Col className="pc" md={{ span: 3, offset: 6 }}>
						<Button
							block
							className="user-update-button"
							variant="secondary"
							onClick={fncOnClickUpdate}
							disabled={loading == '1' || loading == '2'? 'disabled' : ''}
						>
							更 新
						</Button>
					</Col>

					<Col className="sp" xs={{ span: 4 }}>
						<Button
							block
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
					<Col className="sp" xs={{ span: 4, offset: 4 }}>
						<Button block
								className="user-update-button"
								variant="secondary"
								onClick={fncOnClickUpdate}
								disabled={loading == '1' || loading == '2'? 'disabled' : ''}
						>
							更 新
						</Button>
					</Col>
				</Row>
			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>
		</>
	)
}

export default withRouter(UserUpdateVerify);