import React, { useState, useEffect } from 'react';

import Http from 'Utils/Axios';
import Required from 'Component/Required/Required';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData'
import CustomModal from 'Utils/Modal/CustomModal';
import { withRouter } from 'react-router-dom';
import { FormInput } from 'Utils/FormHandlers/HandleFormProperties'
import { Button, Col, Row, FormControl, Form } from 'react-bootstrap';
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";
import {APP_TOP, MSG_NG_USER_URL} from "../../../../Utils/Common/ComConst";

const defaultState = {
	password : "",
	confirm_password : "",
	id : "",
}

function PasswordResetForm({ token, onSubmitData, errors, history, dbconn }) {
	const [fieldData, handleDataFormat] = useHandleFormData({ ...defaultState, token });
	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');

	/**
	 * 初期処理
	 */
    useEffect(() => {
		let aryData = {
			token : token,
			id : dbconn.id,
		}
		Http.post('api/token/email', {
			...aryData
		}).then(response => {
			let result = response.data.results.email;
			if(result == '0'){
				setModalMsg(MSG_NG_USER_URL);
				setShow(true);
			}
		}).catch(error =>{
			setModalMsg(MSG_NG_USER_URL);
			setShow(true);
		});

	 }, []);

	/**
	 * 登録
	 * @param e
	 */
	function handelOnSubmit(e) {
		e.preventDefault();
		fieldData.id = dbconn.id;
		onSubmitData(fieldData);
	}

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		setShow(false);
		let strPram = fncgSetUrlPram(dbconn);
		history.push(APP_TOP + strPram);
	}

	return (
		<>
			<Form className="password-reset-form" onSubmit={handelOnSubmit}>
                新しいパスワードを入力してください。
                
				<Row className="password-reset-form-row disp-left-space-area disp-right-space-area">
					<Required/> パスワード(半角英数字の組み合わせで8文字以上20文字以内で記載してください)
					<FormControl
				    	className="password-reset-form-input-field"
					    type="password" 
					    placeholder="新しいパスワードを入力してください"
					    value={fieldData.password}
						onChange={handleDataFormat}
					    name="password"
						maxLength="20"
					    {...FormInput}
				    />
				    {errors.password && (
				    	<span className="error-span">{errors.password[0]}</span>
				   	)}
				</Row>
				<Row className="password-reset-form-row disp-left-space-area disp-right-space-area">
					<Required/> パスワード(確認のため、もう一度入力してください)
					<FormControl
				    	className="password-reset-form-input-field"
					    type="password" 
					    placeholder="上記と同じパスワードを再入力してください"
					    value={fieldData.confirm_password}
						onChange={handleDataFormat}
					    name="confirm_password"
						maxLength="20"
					    {...FormInput}
				    />
				    {errors.confirm_password && (
				    	<span className="error-span">{errors.confirm_password[0]}</span>
				   	)}
				</Row>


				<Row className="password-reset-button-container disp-left-space-area disp-right-space-area">
					<Col md={{ span: 4, offset: 8 }}>
						<Button block className="password-reset-button" type="submit">				
							登 録
						</Button>
					</Col>
				</Row>
			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>
		</>
	)
}

export default withRouter(PasswordResetForm);
