import React, { useState, useEffect } from 'react';
import Http, {CancelToken} from 'Utils/Axios';
import Auth from 'Utils/Auth';
import {GetDbconn} from './useConn';

function useUser(defData) {
    /** 全てのDB読み込みが完了した時点でfalseが設定されます。 */
    const [loading, setLoading] = useState(true);
    /** 会員情報が設定されます。 */
    const [data, setData] = useState(null);
    const setDefData = (defData && 'setDatas' in defData) ? defData.setDatas : null;
    /** */
    const source = CancelToken.source();
    
    let cache = null;
    useEffect(() => {
        let mount = true;

        if (defData && 'user' in defData) cache = defData.user;

        if (cache != null) {
            // キャッシュから取得
            setData(cache);
            setLoading(false);
        } else {
            // DBから読み込み
            if (mount) loadDB(true);
        }

        return () => {
            mount = false;
        }
    }, []);

    useEffect(() => {
        if (setDefData && data && cache != data) {
            setDefData('user', data);
        }
    }, [data]);

    /**
     * データをDBから読み込みします。
     */
    function loadDB(force) {
        if (!force && loading) return;
        if (!Auth.HasToken()) {
            setLoading(false);
            return;
        }

        setLoading(true);
        setData(null);
        const postdata = GetDbconn();
        Http.post('api/auth/get-user',{...postdata}).then(response => {
            if (response.status == 200) {
                setData(response.data);
            } else {
                Auth.SetAccessToken();
            }
        }).catch(error => {
            if (error.response.status == 401) {
                Auth.SetAccessToken();
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    function cancel() {
        source.cancel('Operation canceled by the user.');
    }

    return [data, setData, loading, loadDB, cancel];
}

export function IsTempUser(user) {
    return user && user.entry_status == '2';
}

export default useUser;