import React from 'react';
import './CardHeader.css';

function CardHeader(props) {
	return (
		<div className="login-card-header-container">
			<div className="pc login-card-header disp-left-space-area">
				{props.text}
			</div>
			<div className="sp sp-size-title">
				{props.text}
			</div>
		</div>
	);
}

 export default CardHeader;