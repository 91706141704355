import React, { useEffect, useState	 } from 'react';

import './RegistrationFormVerify.css';
import Http from '../../../../Utils/Axios';
import Auth from 'Utils/Auth';
import { withRouter } from 'react-router-dom'
import { Button, Col, Row, Form } from 'react-bootstrap';
import CustomModal from '../../../../Utils/Modal/CustomModal';
import {APP_TOP, MSG_NG_USER_ADD} from "../../../../Utils/Common/ComConst";

function RegistrationFormVerify({ data, history, token, returnRegistrationForm, shop, dbconn, urlpram, skipPW, isAuth, line }) {
	const formattedBirthDate = data.birth_date.year + '-' + data.birth_date.month + '-' + data.birth_date.day;
	const formattedInfoMailFlag = data.info_mailflg ? data.info_mailflg === '1' ? '1' : '0' : "";
	const formattedSex = data.user_sex ? data.user_sex === '1' ? '1' : '0' : "";
	const [loading, setLoading] = useState('0');
	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');

	const TokenVerification = (_token) => {
		if (token) {
			Http.post('api/sign-up/token-verification', {
				id: dbconn.id,
				token: _token
			});
		}
	};

	/**
	 * 登録
	 * @param e
	 */
	function handleSubmit(e){
		e.preventDefault();
		setLoading('1');
		let postapi = '';
		if (line) {
			postapi = isAuth ? '/api/auth/line/register' : '/api/line/register';
		} else {
			postapi = isAuth ? '/api/auth/register' : '/api/register';
		}
		Http.post(postapi, {
			...data,
			user_birth_date: formattedBirthDate,
			info_mailflg: formattedInfoMailFlag,
			user_sex: formattedSex,
			token: token
		}).then(response => {
			const strStatus = response.data.status;
			if(strStatus == '200'){
				Auth.SetAccessToken(response.data.token);
				history.push('/registration/complete/' + urlpram);
			}else{
				setLoading('2');
				setModalMsg(MSG_NG_USER_ADD);
				setShow(true);
			}
		}).catch(error => {
			setLoading('2');
			setModalMsg(MSG_NG_USER_ADD);
			setShow(true);
		}).finally(() => {
			TokenVerification(token);
		})
	}

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		setShow(false);
	}

	return (
		<>
			<Form className="registration-form-verify" onSubmit={handleSubmit}>
				{!line && (
					<>
						<div className="text-header first-header disp-left-space-area">▼ログイン情報
						</div>
						<Row className="registration-form-row">			
							<Row className="registration-verify-value">
								メールアドレス
							</Row>
							<Row className="registration-verify-value">
								{data.email} 
							</Row>	
						</Row>
						{!skipPW && (
						<Row className="registration-form-row">
							<Row className="registration-verify-value">
								パスワード
							</Row>
							<Row className="registration-verify-value">
								{data.password != "" && (
									<span> ******** </span>
								)}
							</Row>
						</Row>
						)}
					</>
				)}
				<div className="text-header second-header disp-left-space-area">
					お客様情報
				</div>
				<Row className="registration-form-row">			
					<Row className="registration-verify-value">
						お名前
					</Row>
					<Row className="registration-verify-value">
						{data.user_nm} 
					</Row>	
				</Row>
				<Row className="registration-form-row">		
					<Row className="registration-verify-value">
						フリガナ
					</Row>
					<Row className="registration-verify-value">
						{data.user_kn} 
					</Row>	
				</Row>
				<Row className="registration-form-row">		
					<Row className="registration-verify-value">
						電話番号
					</Row>
					<Row className="registration-verify-value">
						{data.user_tel}
					 </Row>
				</Row>
				<Row className="registration-form-row">
					<Row className="registration-verify-value">
						性別
					</Row>
					<Row className="registration-verify-value">
						{data.user_sex == '0'? '男性' : '女性'}
					</Row>
				</Row>
				<Row className="registration-form-row">
					<Row className="registration-verify-value">
						生年月日
					</Row>
					<Row className="registration-verify-value">
						{formattedBirthDate}
					</Row>
				</Row>

				<Row className="registration-form-row">
					<Row className="registration-verify-value">
						{shop.shop_nm != undefined && shop.shop_nm != ''? shop.shop_nm + 'からの'　: ''}お知らせ{line ? 'メッセージ' : 'メール'}
					</Row>
					<Row className="registration-verify-value">
						{data.info_mailflg == '0'? '希望しない' : '希望する'}
					</Row>
				</Row>

				<Row className="registration-form-row">
					<Col className="pc" md={{ span: 3 }}>
						<Button
							block
							className="btn-back"
							onClick={() => returnRegistrationForm(data)}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
					<Col className="pc" md={{ span: 3, offset: 6 }}>
						<Button
							block
							className="registration-button"
							type="submit"
							variant="secondary"
							disabled={loading == '1' || loading == '2'? 'disabled' : ''}
						>
							登 録
						</Button>
					</Col>

					<Col className="sp" xs={{ span: 4 }}>
						<Button
							block
							className="btn-back"
							onClick={() => returnRegistrationForm(data)}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
					<Col className="sp" xs={{ span: 4, offset: 4 }}>
						<Button
							block
							className="registration-button"
							type="submit"
							variant="secondary"
							disabled={loading == '1' || loading == '2'? 'disabled' : ''}
						>
							登 録
						</Button>
					</Col>
				</Row>
			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>
		</>
	)
}

export default withRouter(RegistrationFormVerify);