import React, { useState } from 'react';

import './ReserveComplete.css';
import Layout from 'layouts/Default/Default';
import { Button, Col, Row } from 'react-bootstrap';
import {fncgSetUrlPram} from "../../../Utils/Common/ComCom";

function RegistrationComplete({ history, data, returnTopForm, dbconn }) {

	function handleReserveList(){
		let strPram = fncgSetUrlPram(dbconn);
		history.push('/reserve-list' + strPram);
	}

	function handleTopPage() {
		returnTopForm();
	}

	function fncSetCompleteButton(){
		return (
			<Row className="justify-content-md-center">
				<Col md={6} >
					<Button className="reserve-complete-button" onClick={handleReserveList}>
						予約内容を確認する
					</Button>
				</Col>
			</Row>
		);
	}

	return (
		<>
			<Layout headerText="予約受付">
				<Col md={{span:6, offset:3}} >
					<div className="reserve-label">
						<p className="mb-0">
							{
								data.comp == '1' ? '予約を受け付けました。' :
									data.comp == '0' ? '既に予約が埋まっています。' :
										data.comp == '2' ? '登録済みの予約と時間が重複しています。' :
											data.comp == '3' ? 'ご指定のスタッフを選択することができません。' :
												data.comp == '4' ? '予約が締め切られています。' :
													data.comp == '5' ? '予約可能回数を超えています。' :
														data.comp == '6' ? 'ご指定のコースを選択することができません。' : ''
							}
						</p>
					</div>
				</Col>

				{data.comp == '1' ? fncSetCompleteButton() : <></>}

				<Row className="justify-content-md-center">
					<Col md={6} >
						<Button className="reserve-complete-button" onClick={handleTopPage}>
							{data.comp == '1' ? '続けて予約する' : '再度、予約する'}
						</Button>
					</Col>
				</Row>

			</Layout >
		</>
	)
}

export default RegistrationComplete;